import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer-3";
import Header from "../component/layout/header-3";
import PageHeader from "../component/section/Product";
import Pagination from "../component/sidebar/pagination";
import PopularPost from "../component/sidebar/popular-post";
import Rating from "../component/sidebar/rating";
import Search from "../component/sidebar/search";
import ShopCategory from "../component/sidebar/shop-category";
import Tags from "../component/sidebar/tags";

import FloatImg from "../component/section/float-icon";

const showResult = "Showing 01 - 12 of 139 Results";


let ProductList1 = [
    {
        imgUrl: 'assets/images/product/Clear-Span.jpg',
        imgAlt: 'CLEAR SPAN',
        title: 'CLEAR SPAN',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Crane-Building.jpg',
        imgAlt: 'CRANE BUILDING',
        title: 'CRANE BUILDING',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Curved-Monoslope.jpg',
        imgAlt: 'CURVED MONOSLOPE',
        title: 'CURVED MONOSLOPE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Curved-Rafter.jpg',
        imgAlt: 'CURVED RAFTER',
        title: 'CURVED RAFTER',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Curved-Rafter-1.jpg',
        imgAlt: 'CURVED RAFTER 1',
        title: 'CURVED RAFTER 1',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/G+1 Building.jpg',
        imgAlt: 'G+1 BUILDING',
        title: 'G+1 BUILDING',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Lean-To.jpg',
        imgAlt: 'LEAN TO',
        title: 'LEAN TO',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Mezzanine Building.jpg',
        imgAlt: 'MEZZANINE BUILDING',
        title: 'MEZZANINE BUILDING',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Monoslope.jpg',
        imgAlt: 'MONOSLOPE',
        title: 'MONOSLOPE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Multi-Gable.jpg',
        imgAlt: 'MULTI GABLE',
        title: 'MULTI GABLE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Multispan.jpg',
        imgAlt: 'MULTISPAN',
        title: 'MULTISPAN',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/Roof-System.jpg',
        imgAlt: 'ROOF SYSTEM',
        title: 'ROOF SYSTEM',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
]
// let ProductList2 = [
//     {
//         imgUrl: 'assets/images/product/MET-C-45 DEG LIP.jpeg',
//         imgAlt: 'MET-C-45 DEG LIP',
//         title: 'MET-C-45 DEG LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-C-90 DEG LIP.jpeg',
//         imgAlt: 'MET-C-90 DEG LIP',
//         title: 'MET-C-90 DEG LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-C-WITHOUT LIP.jpeg',
//         imgAlt: 'MET-C-WITHOUT LIP',
//         title: 'MET-C-WITHOUT LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-Z-45 DEG LIP.jpeg',
//         imgAlt: 'MET-Z-45 DEG LIP',
//         title: 'MET-Z-45 DEG LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-Z-90 DEG LIP.jpeg',
//         imgAlt: 'MET-Z-90 DEG LIP',
//         title: 'MET-Z-90 DEG LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
   
// ]
let ProductList2 = [
    {
        imgUrl: 'assets/images/product/secondary/C Purlin.png',
        imgAlt: 'C Purlin',
        title: 'C Purlin',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/secondary/Met-Rib.png',
        imgAlt: 'Met-Rib',
        title: 'Met-Rib',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/secondary/Sigma Purlin.png',
        imgAlt: 'Sigma Purlin',
        title: 'Sigma Purlin',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/secondary/Z Purlin.png',
        imgAlt: 'Z Purlin',
        title: 'Z Purlin',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
]
// let ProductList3 = [
//     {
//         imgUrl: 'assets/images/product/MET-RIB.png',
//         imgAlt: 'MET-RIB',
//         title: 'MET-RIB',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-STRONG.png',
//         imgAlt: 'MET-STRONG',
//         title: 'MET-STRONG',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-FIN.png',
//         imgAlt: 'MET-FIN',
//         title: 'MET-FIN',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-WAVE.png',
//         imgAlt: 'MET-WAVE',
//         title: 'MET-WAVE',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-CURVE.png',
//         imgAlt: 'MET-CURVE',
//         title: 'MET-CURVE',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-FLEX.png',
//         imgAlt: 'MET-FLEX',
//         title: 'MET-FLEX',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-CLAY.png',
//         imgAlt: 'MET-CLAY',
//         title: 'MET-CLAY',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-DECK.png',
//         imgAlt: 'MET-DECK',
//         title: 'MET-DECK',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
  
// ]
let ProductList3 = [
    {
        imgUrl: 'assets/images/product/roof/Double Side Crimp.png',
        imgAlt: 'Double Side Crimp',
        title: 'Double Side Crimp',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/Eave Crimp.png',
        imgAlt: 'Eave Crimp',
        title: 'Eave Crimp',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/Knurling.png',
        imgAlt: 'Knurling',
        title: 'Knurling',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/Met-Clay.png',
        imgAlt: 'Met-Clay',
        title: 'Met-Clay',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/Met-Curve.png',
        imgAlt: 'Met-Curve',
        title: 'Met-Curve',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/Met-Strong.png',
        imgAlt: 'Met-Strong',
        title: 'Met-Strong',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/Polycarbonate.png',
        imgAlt: 'Polycarbonate',
        title: 'Polycarbonate',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/Met-Deck.png',
        imgAlt: 'Met-Deck',
        title: 'Met-Deck',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
]
let ProductList4 = [
    {
        imgUrl: 'assets/images/product/accessories/ANCHOR BOLT - 1.png',
        imgAlt: 'ANCHOR BOLT - 1',
        title: 'ANCHOR BOLT - 1',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/ANCHOR BOLT - 3.png',
        imgAlt: 'ANCHOR BOLT - 3',
        title: 'ANCHOR BOLT - 3',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/ANCHOR BOLT.png',
        imgAlt: 'ANCHOR BOLT',
        title: 'ANCHOR BOLT',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/ANCHOR FASTENERS.png',
        imgAlt: 'ANCHOR FASTENERS',
        title: 'ANCHOR FASTENERS',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Box Gutter.png',
        imgAlt: 'Box Gutter',
        title: 'Box Gutter',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/BRACE ROD.png',
        imgAlt: 'BRACE ROD',
        title: 'BRACE ROD',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
    {
        imgUrl: 'assets/images/product/accessories/CLEAT.png',
        imgAlt: 'CLEAT',
        title: 'CLEAT',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/CONNECTION BOLTS.png',
        imgAlt: 'CONNECTION BOLTS',
        title: 'CONNECTION BOLTS',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Corner Flashing.png',
        imgAlt: 'Corner Flashing',
        title: 'Corner Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Down Take Pipe Shoe.png',
        imgAlt: 'Down Take Pipe Shoe',
        title: 'Down Take Pipe Shoe',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Down Take Pipe.png',
        imgAlt: 'Down Take Pipe',
        title: 'Down Take Pipe',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Drip Flashing.png',
        imgAlt: 'Drip Flashing',
        title: 'Drip Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/FISH PLATE.png',
        imgAlt: 'FISH PLATE',
        title: 'FISH PLATE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/FLANGE BRACE.png',
        imgAlt: 'FLANGE BRACE',
        title: 'FLANGE BRACE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/FULL CLEAT.png',
        imgAlt: 'FULL CLEAT',
        title: 'FULL CLEAT',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Gutter Closure.png',
        imgAlt: 'Gutter Closure',
        title: 'Gutter Closure',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Gutter.png',
        imgAlt: 'Gutter',
        title: 'Gutter',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/HILL SIDE WASHER.png',
        imgAlt: 'HILL SIDE WASHER',
        title: 'HILL SIDE WASHER',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/INDUSTRIAL LOUVER.png',
        imgAlt: 'INDUSTRIAL LOUVER',
        title: 'INDUSTRIAL LOUVER',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/L Angle.png',
        imgAlt: 'L Angle',
        title: 'L Angle',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/L FLashing.png',
        imgAlt: 'L FLashing',
        title: 'L FLashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/LOOSE CLEAT- 1.png',
        imgAlt: 'LOOSE CLEAT - 1',
        title: 'LOOSE CLEAT - 1',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/LOOSE CLEAT - 2.png',
        imgAlt: 'LOOSE CLEAT - 2',
        title: 'BRACE ROD',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/LOOSE PLATE - 1.png',
        imgAlt: 'LOOSE PLATE - 1',
        title: 'LOOSE PLATE - 1',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/LOOSE PLATE - 2.png',
        imgAlt: 'LOOSE PLATE - 2',
        title: 'LOOSE PLATE - 2',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/LOOSE PLATE - 3.png',
        imgAlt: 'LOOSE PLATE - 3',
        title: 'LOOSE PLATE - 3',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/LOOSE PLATE - 4.png',
        imgAlt: 'LOOSE PLATE - 4',
        title: 'LOOSE PLATE - 4',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/LOOSE PLATE.png',
        imgAlt: 'LOOSE PLATE',
        title: 'LOOSE PLATE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Louvre.png',
        imgAlt: 'Louvre',
        title: 'Louvre',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/MALE FOAM FILLER.png',
        imgAlt: 'MALE FOAM FILLER',
        title: 'MALE FOAM FILLER',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/NUTS AND WASHERS.png',
        imgAlt: 'NUTS AND WASHERS',
        title: 'NUTS AND WASHERS',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Panel Closure.png',
        imgAlt: 'Panel Closure',
        title: 'Panel Closure',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Plain Ridge.png',
        imgAlt: 'Plain Ridge',
        title: 'Plain Ridge',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Profile Down Take Pipe.png',
        imgAlt: 'Profile Down Take Pipe',
        title: 'Profile Down Take Pipe',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Profiled Down Take Pipe.png',
        imgAlt: 'Profiled Down Take Pipe',
        title: 'Profiled Down Take Pipe',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Profiled Ridge.png',
        imgAlt: 'Profiled Ridge',
        title: 'Profiled Ridge',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Rake Angle Flashing.png',
        imgAlt: 'Rake Angle Flashing',
        title: 'Rake Angle Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/SCREWS.png',
        imgAlt: 'SCREWS',
        title: 'SCREWS',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/T - CLIP.png',
        imgAlt: 'T - CLIP',
        title: 'T - CLIP',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/TEMPLATE.png',
        imgAlt: 'TEMPLATE',
        title: 'TEMPLATE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/TURN BUCKLE.png',
        imgAlt: 'TURN BUCKLE',
        title: 'TURN BUCKLE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Valley Gutter.png',
        imgAlt: 'Valley Gutter',
        title: 'Valley Gutter',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Z Flashing.png',
        imgAlt: 'Z Flashing',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
]
const ShopPage = () => {
    const [GridList, setGridList] = useState(true);
    const open1 = () => {
        document.getElementById("open1").style.backgroundColor="white";
        document.getElementById("open1").style.color="Black";
        document.getElementById("open2").style.backgroundColor="#006fb4";
        document.getElementById("open2").style.color="White";
        document.getElementById("open3").style.backgroundColor="#006fb4";
        document.getElementById("open3").style.color="White";
        document.getElementById("open4").style.backgroundColor="#006fb4";
        document.getElementById("open4").style.color="White";
        document.getElementById("list1").style.display="block";
        document.getElementById("list2").style.display="none";
        document.getElementById("list3").style.display="none";
        document.getElementById("list4").style.display="none";
      }
      const open2 = () => {
        document.getElementById("open1").style.backgroundColor="#006fb4";
        document.getElementById("open1").style.color="White";
        document.getElementById("open2").style.backgroundColor="white";
        document.getElementById("open2").style.color="Black";
        document.getElementById("open3").style.backgroundColor="#006fb4";
        document.getElementById("open3").style.color="White";
        document.getElementById("open4").style.backgroundColor="#006fb4";
        document.getElementById("open4").style.color="White";
        document.getElementById("list1").style.display="none";
        document.getElementById("list2").style.display="block";
        document.getElementById("list3").style.display="none";
        document.getElementById("list4").style.display="none";
      }
      const open3 = () => {
        document.getElementById("open1").style.backgroundColor="#006fb4";
        document.getElementById("open1").style.color="White";
        document.getElementById("open3").style.backgroundColor="white";
        document.getElementById("open3").style.color="Black";
        document.getElementById("open2").style.backgroundColor="#006fb4";
        document.getElementById("open2").style.color="White";
        document.getElementById("open4").style.backgroundColor="#006fb4";
        document.getElementById("open4").style.color="White";
        document.getElementById("list1").style.display="none";
        document.getElementById("list2").style.display="none";
        document.getElementById("list3").style.display="block";
        document.getElementById("list4").style.display="none";
      }
      const open4 = () => {
        document.getElementById("open2").style.backgroundColor="#006fb4";
        document.getElementById("open2").style.color="White";
        document.getElementById("open4").style.backgroundColor="white";
        document.getElementById("open4").style.color="Black";
        document.getElementById("open3").style.backgroundColor="#006fb4";
        document.getElementById("open3").style.color="White";
        document.getElementById("open1").style.backgroundColor="#006fb4";
        document.getElementById("open1").style.color="White";
        document.getElementById("list1").style.display="none";
        document.getElementById("list2").style.display="none";
        document.getElementById("list3").style.display="none";
        document.getElementById("list4").style.display="block";
      }
    return (
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="shop-page padding-tb">
                <div className="container">
                <div className="row">
                        <div className="col-md-4 col-12">
                        <div className="course-side-cetagory">
            <div className="csc-title" style={{backgroundColor:"white"}}>
               
            </div>
            <div className="csc-content">
                <div className="csdc-lists">
                    <ul className="lab-ul">
                            <li  className="reveal bigEntrance">
                            <span id="open1" onClick={open1} style={{backgroundColor:"white",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"black",width:"100%"}}>Primary Members</span>
                 
                            </li>
                            <li  className="reveal bigEntrance">
                            <span id="open2" onClick={open2} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>Secondary Members</span>
                      </li>
                            <li  className="reveal bigEntrance">
                            <span id="open3" onClick={open3} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>Roof Sheets</span><br></br>
                     </li>
                            <li  className="reveal bigEntrance">
                           
                            <span id="open4" onClick={open4} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>Accessories</span>
                            </li>
                    </ul>
                </div>
            </div>
        </div>
                            
                            
                                  <br></br>
                          <br></br></div>
                        <div className="col-md-8"><div id="list1" className="row justify-content-center" >
                        <h2 className="animate__animated animate__bounceInDown">PRIMARY MEMBERS / MAIN FRAMES</h2>
                        <p className="animate__animated animate__fadeInUp">Primary members are the main load carrying and support members of a pre-engineered building. The main frame members include columns, rafters and other supporting members. The shape and size of these members vary based on the application and requirements. The frame is erected by bolting the end plates of connecting sections together.</p>
                        <div className="col-lg-12 col-12">
                            <article>
                                <div className={`shop-product-wrap row justify-content-center ${GridList ? "grid" : "list"}`} >
                                    {ProductList1.map((val, i) => (
                                        <div className="col-lg-4 col-md-6 col-12" key={i}>
                                            <div className="product-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <span style={{fontFamily:"Impact"}}>{`${val.imgAlt}`}</span>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5><Link to="/shop-single">{val.title}</Link></h5>
                                                    <p className="productRating"><Rating /></p>
                                                </div>
                                            </div>
                                            <div className="product-list-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <a href="#"><i className="icofont-eye"></i></a>
                                                        <a href="#"><i className="icofont-heart"></i></a>
                                                        <a href="#"><i className="icofont-cart-alt"></i></a>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5><Link to="/shop-single">{val.title}</Link></h5>
                                                    <p className="productRating"><Rating /></p>
                                                    <h6>{val.price}</h6>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </article>
                        </div>
                    </div>
                    <div id="list2"className="row justify-content-center" >
                        <h2 className="animate__animated animate__bounceInDown">SECONDARY MEMBERS / COLD FORMED MEMBERS</h2>
                        <p className="animate__animated animate__fadeInUp">Metco has installed most modern roll forming machines to manufacture a variety of standardized and customized purlins, maintaining international quality standards. There are variety of CNC controllers to conert the stroke, forming and punching parameters for optimizing the quality, design stability and finish.<br></br><br></br>

<span style={{color:"blueviolet"}}>PURLINS & GRITS</span><br></br><br></br>
Purlin & Grits are used as the secondary members which severs as a support to roof sheeting and wall cladding. They can be lapped and nested at the supports which creates a continuous beam configuration. They are supported on columns, rafters or building walls.
<ul>
<li>C-Type</li>
<li>Z-Type</li></ul></p>
                        <div className="col-lg-12 col-12">
                            <article>
                              
                                <div className={`shop-product-wrap row justify-content-center ${GridList ? "grid" : "list"}`} >
                                    {ProductList2.map((val, i) => (
                                        <div className="col-lg-3 col-md-6 col-12" key={i}>
                                            <div className="product-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <span style={{fontFamily:"Impact"}}>{`${val.imgAlt}`}</span>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5><Link to="/shop-single">{val.title}</Link></h5>
                                                    <p className="productRating"><Rating /></p>
                                                </div>
                                            </div>
                                            <div className="product-list-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <a href="#"><i className="icofont-eye"></i></a>
                                                        <a href="#"><i className="icofont-heart"></i></a>
                                                        <a href="#"><i className="icofont-cart-alt"></i></a>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5><Link to="/shop-single">{val.title}</Link></h5>
                                                    <p className="productRating"><Rating /></p>
                                                    <h6>{val.price}</h6>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </article>
                        </div>
                    </div>
                    <div id="list3"className="row justify-content-center"  >
                        <h2 className="animate__animated animate__bounceInDown">ROOFING SHEETS</h2>
                        <div className="col-lg-12 col-12">
                            <article>
                              
                                <div className={`shop-product-wrap row justify-content-center ${GridList ? "grid" : "list"}`} >
                                    {ProductList3.map((val, i) => (
                                        <div className="col-lg-3 col-md-6 col-12" key={i}>
                                            <div className="product-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <span style={{fontFamily:"Impact"}}>{`${val.imgAlt}`}</span>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5><Link to="/shop-single">{val.title}</Link></h5>
                                                    <p className="productRating"><Rating /></p>
                                                </div>
                                            </div>
                                            <div className="product-list-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <a href="#"><i className="icofont-eye"></i></a>
                                                        <a href="#"><i className="icofont-heart"></i></a>
                                                        <a href="#"><i className="icofont-cart-alt"></i></a>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5><Link to="/shop-single">{val.title}</Link></h5>
                                                    <p className="productRating"><Rating /></p>
                                                    <h6>{val.price}</h6>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </article>
                        </div>
                    </div>
                    <div id="list4"className="row justify-content-center" >
                        <h2 className="animate__animated animate__bounceInDown">ACCESSORIES</h2>
                        <div className="col-lg-12 col-12">
                            <article>
                              
                                <div className={`shop-product-wrap row justify-content-center ${GridList ? "grid" : "list"}`} >
                                    {ProductList4.map((val, i) => (
                                        <div className="col-lg-3 col-md-6 col-12" key={i}>
                                            <div className="product-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <span style={{fontFamily:"Impact"}}>{`${val.imgAlt}`}</span>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5><Link to="/shop-single">{val.title}</Link></h5>
                                                    <p className="productRating"><Rating /></p>
                                                </div>
                                            </div>
                                            <div className="product-list-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <a href="#"><i className="icofont-eye"></i></a>
                                                        <a href="#"><i className="icofont-heart"></i></a>
                                                        <a href="#"><i className="icofont-cart-alt"></i></a>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5><Link to="/shop-single">{val.title}</Link></h5>
                                                    <p className="productRating"><Rating /></p>
                                                    <h6>{val.price}</h6>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </article>
                        </div>
                    </div></div>
                    </div>
                    
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default ShopPage;
import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer-3";
import Header from "../component/layout/header-3";
import PageHeader from "../component/section/Product";
import Pagination from "../component/sidebar/pagination";
import PopularPost from "../component/sidebar/popular-post";
import Rating from "../component/sidebar/rating";
import Search from "../component/sidebar/search";
import ShopCategory from "../component/sidebar/shop-category";
import Tags from "../component/sidebar/tags";

import FloatImg from "../component/section/float-icon";

const showResult = "Showing 01 - 12 of 139 Results";


// let ProductList2 = [
//     {
//         imgUrl: 'assets/images/product/MET-C-45 DEG LIP.jpeg',
//         imgAlt: 'MET-C-45 DEG LIP',
//         title: 'MET-C-45 DEG LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-C-90 DEG LIP.jpeg',
//         imgAlt: 'MET-C-90 DEG LIP',
//         title: 'MET-C-90 DEG LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-C-WITHOUT LIP.jpeg',
//         imgAlt: 'MET-C-WITHOUT LIP',
//         title: 'MET-C-WITHOUT LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-Z-45 DEG LIP.jpeg',
//         imgAlt: 'MET-Z-45 DEG LIP',
//         title: 'MET-Z-45 DEG LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-Z-90 DEG LIP.jpeg',
//         imgAlt: 'MET-Z-90 DEG LIP',
//         title: 'MET-Z-90 DEG LIP',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
   
// ]
let ProductList2 = [
    {
        imgUrl: 'assets/images/product/secondary/C Purlin.png',
        imgAlt: 'C Purlin',
        title: 'C Purlin',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
    {
        imgUrl: 'assets/images/product/secondary/Sigma Purlin.png',
        imgAlt: 'M Purlin',
        title: 'M Purlin',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/secondary/Z Purlin.png',
        imgAlt: 'Z Purlin',
        title: 'Z Purlin',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
]
// let ProductList3 = [
//     {
//         imgUrl: 'assets/images/product/MET-RIB.png',
//         imgAlt: 'MET-RIB',
//         title: 'MET-RIB',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-STRONG.png',
//         imgAlt: 'MET-STRONG',
//         title: 'MET-STRONG',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-FIN.png',
//         imgAlt: 'MET-FIN',
//         title: 'MET-FIN',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-WAVE.png',
//         imgAlt: 'MET-WAVE',
//         title: 'MET-WAVE',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-CURVE.png',
//         imgAlt: 'MET-CURVE',
//         title: 'MET-CURVE',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-FLEX.png',
//         imgAlt: 'MET-FLEX',
//         title: 'MET-FLEX',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-CLAY.png',
//         imgAlt: 'MET-CLAY',
//         title: 'MET-CLAY',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
//     {
//         imgUrl: 'assets/images/product/MET-DECK.png',
//         imgAlt: 'MET-DECK',
//         title: 'MET-DECK',
//         price: '$200.00',
//         desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
//     },
  
// ]
let ProductList3 = [
    {
        imgUrl: 'assets/images/product/roof/MET-CLAY-Model.jpg',
        imgAlt: 'MET CLAY',
        title: 'MET CLAY',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/MET-CURVE-Model.jpg',
        imgAlt: 'MET CURVE',
        title: 'MET CURVE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/MET-DECK-Model.jpg',
        imgAlt: 'MET DECK',
        title: 'MET DECK',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/MET-FIN-Model.jpg',
        imgAlt: 'MET FIN',
        title: 'MET FIN',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/MET-FLEX-Model.jpg',
        imgAlt: 'MET FLEX',
        title: 'MET FLEX',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/MET-RIB-Model.jpg',
        imgAlt: 'MET RIB',
        title: 'MET RIB',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/MET-STRONG-Model.jpg',
        imgAlt: 'MET STRONG',
        title: 'MET STRONG',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/roof/MET-WAVE-Model.jpg',
        imgAlt: 'MET WAVE',
        title: 'MET WAVE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
]
let ProductList4 = [
    {
        imgUrl: 'assets/images/product/accessories/ANCHOR BOLT - 1.jpg',
        imgAlt: 'ANCHOR BOLT - 1',
        title: 'ANCHOR BOLT - 1',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/ANCHOR FASTENERS.jpg',
        imgAlt: 'ANCHOR FASTENERS',
        title: 'ANCHOR FASTENERS',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Box Gutter.jpg',
        imgAlt: 'ANCHOR GUTTER',
        title: 'ANCHOR GUTTER',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/BRACE ROD 1.jpg',
        imgAlt: 'BRACE ROD - 1',
        title: 'Box Gutter',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/CLEAT.jpg',
        imgAlt: 'CLEAT',
        title: 'CONNECTION BOLTS',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/CONNECTION BOLTS.jpg',
        imgAlt: 'CONNECTION BOLTS',
        title: 'Corner Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Corner Flashing.jpg',
        imgAlt: 'Corner Flashing',
        title: 'Corner Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Double Side Crimp.jpg',
        imgAlt: 'Double Side Crimp',
        title: 'Down Take Pipe',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Down Take Pipe.jpg',
        imgAlt: 'Down Take Pipe',
        title: 'Drip Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Down Take Pipe Shoe.jpg',
        imgAlt: 'Down Take Pipe Shoe',
        title: 'FISH PLATE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Drip Flashing.jpg',
        imgAlt: 'Drip Flashing',
        title: 'FLANGE BRACE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Eave Crimp.jpg',
        imgAlt: 'Eave Crimp',
        title: 'FULL CLEAT',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/FISH PLATE.jpg',
        imgAlt: 'FISH PLATE',
        title: 'Gutter Closure',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/FLANGE BRACE.jpg',
        imgAlt: 'FLANGE BRACE',
        title: 'Gutter',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
    {
        imgUrl: 'assets/images/product/accessories/FULL CLEAT All.jpg',
        imgAlt: 'FULL CLEATS',
        title: 'INDUSTRIAL LOUVER',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Gutter Closure.jpg',
        imgAlt: 'GUTTER CLOSURE',
        title: 'L Angle',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Gutter.jpg',
        imgAlt: 'GUTTER',
        title: 'L FLashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/HILL SIDE WASHER.jpg',
        imgAlt: 'HILL SIDE WASHER',
        title: 'LOOSE CLEAT - 1',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Knurling.jpg',
        imgAlt: 'KNURLING',
        title: 'BRACE ROD',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/L Angle.jpg',
        imgAlt: 'L ANGLE',
        title: 'LOOSE PLATE - 1',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/L FLashing.jpg',
        imgAlt: 'L FLASHING',
        title: 'LOOSE PLATE - 2',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
    {
        imgUrl: 'assets/images/product/accessories/LOOSE CLEAT-1-2.jpg',
        imgAlt: 'LOOSE CLEAT',
        title: 'LOOSE PLATE - 4',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
    {
        imgUrl: 'assets/images/product/accessories/Louvre.jpg',
        imgAlt: 'LOURVE',
        title: 'Plain Ridge',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/MALE FOAM FILLER.jpg',
        imgAlt: 'MALE FOAM FILLER',
        title: 'Profile Down Take Pipe',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
  
    {
        imgUrl: 'assets/images/product/accessories/NUTS AND WASHERS.jpg',
        imgAlt: 'NUTS AND WASHERS',
        title: 'TEMPLATE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Panel Closure.jpg',
        imgAlt: 'PANEL CLOSURE',
        title: 'TURN BUCKLE',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Plain Ridge.jpg',
        imgAlt: 'PLAIN RIDGE',
        title: 'Valley Gutter',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Polycarbonate.jpg',
        imgAlt: 'POLYCARBONATE',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Profile Down Take Pipe.jpg',
        imgAlt: 'Profile Down Take Pipe',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Profiled Down Take Pipe.jpg',
        imgAlt: 'ProfileD Down Take Pipe 1',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Profiled Down Take Pipe 2.jpg',
        imgAlt: 'ProfileD Down Take Pipe 2',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Profiled Ridge.jpg',
        imgAlt: 'PROFILED RIDGE',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Rake Angle Flashing.jpg',
        imgAlt: 'RAKE ANGLE FLASHING',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/SAG ROD.jpg',
        imgAlt: 'SAG ROD',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/SCREWS.jpg',
        imgAlt: 'SCREWS',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
   
    {
        imgUrl: 'assets/images/product/accessories/T - CLIP.jpg',
        imgAlt: 'T CLIP',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/TEMPLATE.jpg',
        imgAlt: 'TEMPLATE',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/TURN BUCKLE.jpg',
        imgAlt: 'TURN BUCKLE',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/product/accessories/Valley Gutter.jpg',
        imgAlt: 'VALLEY GUTTER',
        title: 'Z Flashing',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
]
const ShopPage = () => {
    const [GridList, setGridList] = useState(true);
    const open1 = () => {
        document.getElementById("open1").style.backgroundColor="white";
        document.getElementById("open1").style.color="Black";
        document.getElementById("open2").style.backgroundColor="#006fb4";
        document.getElementById("open2").style.color="White";
        document.getElementById("open3").style.backgroundColor="#006fb4";
        document.getElementById("open3").style.color="White";
        document.getElementById("open4").style.backgroundColor="#006fb4";
        document.getElementById("open4").style.color="White";
        document.getElementById("open5").style.backgroundColor="#006fb4";
        document.getElementById("open5").style.color="White";
        document.getElementById("open6").style.backgroundColor="#006fb4";
        document.getElementById("open6").style.color="White";
        document.getElementById("open7").style.backgroundColor="#006fb4";
        document.getElementById("open7").style.color="White";
        document.getElementById("open8").style.backgroundColor="#006fb4";
        document.getElementById("open8").style.color="White";
        document.getElementById("open9").style.backgroundColor="#006fb4";
        document.getElementById("open9").style.color="White";
        document.getElementById("list1").style.display="block";
        document.getElementById("list2").style.display="none";
        document.getElementById("list3").style.display="none";
        document.getElementById("list4").style.display="none";
        document.getElementById("list5").style.display="none";
        document.getElementById("list6").style.display="none";
        document.getElementById("list7").style.display="none";
        document.getElementById("list8").style.display="none";
        document.getElementById("list9").style.display="none";
      }
      const open2 = () => {
        document.getElementById("open2").style.backgroundColor="white";
        document.getElementById("open2").style.color="Black";
        document.getElementById("open1").style.backgroundColor="#006fb4";
        document.getElementById("open1").style.color="White";
        document.getElementById("open3").style.backgroundColor="#006fb4";
        document.getElementById("open3").style.color="White";
        document.getElementById("open4").style.backgroundColor="#006fb4";
        document.getElementById("open4").style.color="White";
        document.getElementById("open5").style.backgroundColor="#006fb4";
        document.getElementById("open5").style.color="White";
        document.getElementById("open6").style.backgroundColor="#006fb4";
        document.getElementById("open6").style.color="White";
        document.getElementById("open7").style.backgroundColor="#006fb4";
        document.getElementById("open7").style.color="White";
        document.getElementById("open8").style.backgroundColor="#006fb4";
        document.getElementById("open8").style.color="White";
        document.getElementById("open9").style.backgroundColor="#006fb4";
        document.getElementById("open9").style.color="White";
        document.getElementById("list1").style.display="none";
        document.getElementById("list2").style.display="block";
        document.getElementById("list3").style.display="none";
        document.getElementById("list4").style.display="none";
        document.getElementById("list5").style.display="none";
        document.getElementById("list6").style.display="none";
        document.getElementById("list7").style.display="none";
        document.getElementById("list8").style.display="none";
        document.getElementById("list9").style.display="none";
      }
      const open3 = () => {
        document.getElementById("open3").style.backgroundColor="white";
        document.getElementById("open3").style.color="Black";
        document.getElementById("open2").style.backgroundColor="#006fb4";
        document.getElementById("open2").style.color="White";
        document.getElementById("open1").style.backgroundColor="#006fb4";
        document.getElementById("open1").style.color="White";
        document.getElementById("open4").style.backgroundColor="#006fb4";
        document.getElementById("open4").style.color="White";
        document.getElementById("open5").style.backgroundColor="#006fb4";
        document.getElementById("open5").style.color="White";
        document.getElementById("open6").style.backgroundColor="#006fb4";
        document.getElementById("open6").style.color="White";
        document.getElementById("open7").style.backgroundColor="#006fb4";
        document.getElementById("open7").style.color="White";
        document.getElementById("open8").style.backgroundColor="#006fb4";
        document.getElementById("open8").style.color="White";
        document.getElementById("open9").style.backgroundColor="#006fb4";
        document.getElementById("open9").style.color="White";
        document.getElementById("list1").style.display="none";
        document.getElementById("list2").style.display="none";
        document.getElementById("list3").style.display="block";
        document.getElementById("list4").style.display="none";
        document.getElementById("list5").style.display="none";
        document.getElementById("list6").style.display="none";
        document.getElementById("list7").style.display="none";
        document.getElementById("list8").style.display="none";
        document.getElementById("list9").style.display="none";
      }
      const open4 = () => {
        document.getElementById("open4").style.backgroundColor="white";
        document.getElementById("open4").style.color="Black";
        document.getElementById("open2").style.backgroundColor="#006fb4";
        document.getElementById("open2").style.color="White";
        document.getElementById("open3").style.backgroundColor="#006fb4";
        document.getElementById("open3").style.color="White";
        document.getElementById("open1").style.backgroundColor="#006fb4";
        document.getElementById("open1").style.color="White";
        document.getElementById("open5").style.backgroundColor="#006fb4";
        document.getElementById("open5").style.color="White";
        document.getElementById("open6").style.backgroundColor="#006fb4";
        document.getElementById("open6").style.color="White";
        document.getElementById("open7").style.backgroundColor="#006fb4";
        document.getElementById("open7").style.color="White";
        document.getElementById("open8").style.backgroundColor="#006fb4";
        document.getElementById("open8").style.color="White";
        document.getElementById("open9").style.backgroundColor="#006fb4";
        document.getElementById("open9").style.color="White";
        document.getElementById("list1").style.display="none";
        document.getElementById("list2").style.display="none";
        document.getElementById("list3").style.display="none";
        document.getElementById("list4").style.display="block";
        document.getElementById("list5").style.display="none";
        document.getElementById("list6").style.display="none";
        document.getElementById("list7").style.display="none";
        document.getElementById("list8").style.display="none";
        document.getElementById("list9").style.display="none";
      }
      const open5 = () => {
        document.getElementById("open5").style.backgroundColor="white";
        document.getElementById("open5").style.color="Black";
        document.getElementById("open2").style.backgroundColor="#006fb4";
        document.getElementById("open2").style.color="White";
        document.getElementById("open3").style.backgroundColor="#006fb4";
        document.getElementById("open3").style.color="White";
        document.getElementById("open4").style.backgroundColor="#006fb4";
        document.getElementById("open4").style.color="White";
        document.getElementById("open1").style.backgroundColor="#006fb4";
        document.getElementById("open1").style.color="White";
        document.getElementById("open6").style.backgroundColor="#006fb4";
        document.getElementById("open6").style.color="White";
        document.getElementById("open7").style.backgroundColor="#006fb4";
        document.getElementById("open7").style.color="White";
        document.getElementById("open8").style.backgroundColor="#006fb4";
        document.getElementById("open8").style.color="White";
        document.getElementById("open9").style.backgroundColor="#006fb4";
        document.getElementById("open9").style.color="White";
        document.getElementById("list1").style.display="none";
        document.getElementById("list2").style.display="none";
        document.getElementById("list3").style.display="none";
        document.getElementById("list4").style.display="none";
        document.getElementById("list5").style.display="block";
        document.getElementById("list6").style.display="none";
        document.getElementById("list7").style.display="none";
        document.getElementById("list8").style.display="none";
        document.getElementById("list9").style.display="none";
        }
        const open6 = () => {
            document.getElementById("open6").style.backgroundColor="white";
            document.getElementById("open6").style.color="Black";
            document.getElementById("open2").style.backgroundColor="#006fb4";
            document.getElementById("open2").style.color="White";
            document.getElementById("open3").style.backgroundColor="#006fb4";
            document.getElementById("open3").style.color="White";
            document.getElementById("open4").style.backgroundColor="#006fb4";
            document.getElementById("open4").style.color="White";
            document.getElementById("open5").style.backgroundColor="#006fb4";
            document.getElementById("open5").style.color="White";
            document.getElementById("open1").style.backgroundColor="#006fb4";
            document.getElementById("open1").style.color="White";
            document.getElementById("open7").style.backgroundColor="#006fb4";
            document.getElementById("open7").style.color="White";
            document.getElementById("open8").style.backgroundColor="#006fb4";
            document.getElementById("open8").style.color="White";
            document.getElementById("open9").style.backgroundColor="#006fb4";
            document.getElementById("open9").style.color="White";
            document.getElementById("list1").style.display="none";
            document.getElementById("list2").style.display="none";
            document.getElementById("list3").style.display="none";
            document.getElementById("list4").style.display="none";
            document.getElementById("list5").style.display="none";
            document.getElementById("list6").style.display="block";
            document.getElementById("list7").style.display="none";
            document.getElementById("list8").style.display="none";
            document.getElementById("list9").style.display="none";
        }
        const open7 = () => {
            document.getElementById("open7").style.backgroundColor="white";
            document.getElementById("open7").style.color="Black";
            document.getElementById("open2").style.backgroundColor="#006fb4";
            document.getElementById("open2").style.color="White";
            document.getElementById("open3").style.backgroundColor="#006fb4";
            document.getElementById("open3").style.color="White";
            document.getElementById("open4").style.backgroundColor="#006fb4";
            document.getElementById("open4").style.color="White";
            document.getElementById("open5").style.backgroundColor="#006fb4";
            document.getElementById("open5").style.color="White";
            document.getElementById("open6").style.backgroundColor="#006fb4";
            document.getElementById("open6").style.color="White";
            document.getElementById("open1").style.backgroundColor="#006fb4";
            document.getElementById("open1").style.color="White";
            document.getElementById("open8").style.backgroundColor="#006fb4";
            document.getElementById("open8").style.color="White";
            document.getElementById("open9").style.backgroundColor="#006fb4";
            document.getElementById("open9").style.color="White";
            document.getElementById("list1").style.display="none";
            document.getElementById("list2").style.display="none";
            document.getElementById("list3").style.display="none";
            document.getElementById("list4").style.display="none";
            document.getElementById("list5").style.display="none";
            document.getElementById("list6").style.display="none";
            document.getElementById("list7").style.display="block";
            document.getElementById("list8").style.display="none";
            document.getElementById("list9").style.display="none";
        }
        const open8 = () => {
            document.getElementById("open8").style.backgroundColor="white";
            document.getElementById("open8").style.color="Black";
            document.getElementById("open2").style.backgroundColor="#006fb4";
            document.getElementById("open2").style.color="White";
            document.getElementById("open3").style.backgroundColor="#006fb4";
            document.getElementById("open3").style.color="White";
            document.getElementById("open4").style.backgroundColor="#006fb4";
            document.getElementById("open4").style.color="White";
            document.getElementById("open5").style.backgroundColor="#006fb4";
            document.getElementById("open5").style.color="White";
            document.getElementById("open6").style.backgroundColor="#006fb4";
            document.getElementById("open6").style.color="White";
            document.getElementById("open7").style.backgroundColor="#006fb4";
            document.getElementById("open7").style.color="White";
            document.getElementById("open1").style.backgroundColor="#006fb4";
            document.getElementById("open1").style.color="White";
            document.getElementById("open9").style.backgroundColor="#006fb4";
            document.getElementById("open9").style.color="White";
            document.getElementById("list1").style.display="none";
            document.getElementById("list2").style.display="none";
            document.getElementById("list3").style.display="none";
            document.getElementById("list4").style.display="none";
            document.getElementById("list5").style.display="none";
            document.getElementById("list6").style.display="none";
            document.getElementById("list7").style.display="none";
            document.getElementById("list8").style.display="block";
            document.getElementById("list9").style.display="none";
        }
        const open9 = () => {
            document.getElementById("open9").style.backgroundColor="white";
            document.getElementById("open9").style.color="Black";
            document.getElementById("open8").style.backgroundColor="#006fb4";
            document.getElementById("open8").style.color="white";
            document.getElementById("open2").style.backgroundColor="#006fb4";
            document.getElementById("open2").style.color="White";
            document.getElementById("open3").style.backgroundColor="#006fb4";
            document.getElementById("open3").style.color="White";
            document.getElementById("open4").style.backgroundColor="#006fb4";
            document.getElementById("open4").style.color="White";
            document.getElementById("open5").style.backgroundColor="#006fb4";
            document.getElementById("open5").style.color="White";
            document.getElementById("open6").style.backgroundColor="#006fb4";
            document.getElementById("open6").style.color="White";
            document.getElementById("open7").style.backgroundColor="#006fb4";
            document.getElementById("open7").style.color="White";
            document.getElementById("open1").style.backgroundColor="#006fb4";
            document.getElementById("open1").style.color="White";
            document.getElementById("list1").style.display="none";
            document.getElementById("list2").style.display="none";
            document.getElementById("list3").style.display="none";
            document.getElementById("list4").style.display="none";
            document.getElementById("list5").style.display="none";
            document.getElementById("list6").style.display="none";
            document.getElementById("list7").style.display="none";
            document.getElementById("list8").style.display="none";
            document.getElementById("list9").style.display="block";
        }
        const handleSort = (value) => {
            alert(value);
            document.getElementById(value).style.display="block";
          }
          const hideSort = (value) => {
            alert(value);
            document.getElementById(value).style.display="none";
          }
    return (
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="shop-page padding-tb">
                <div className="container">
                <div className="row">
                        <div className="col-md-4 col-12">
                        <div className="course-side-cetagory">
          
            <div className="csc-content">
                <div className="csdc-lists">
                    <ul className="lab-ul">
                    <li  className="animate__animated animate__fadeInLeft">
                            <span id="open1" onClick={open1} style={{backgroundColor:"white",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"black",width:"100%"}}>Steel Structures</span>
                 
                            </li>
                    <li  className="animate__animated animate__fadeInLeft">
                            <span id="open9" onClick={open9} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>PEB (Pre Engineered Building)</span>
                 
                            </li>
                            <li  className="animate__animated animate__fadeInLeft">
                            <span id="open2" onClick={open2} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>LGSF Structures</span>
                 
                            </li>
                            <li  className="animate__animated animate__fadeInLeft">
                            <span id="open3" onClick={open3} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>Solar Structures</span>
                 
                            </li>
                            <li  className="animate__animated animate__fadeInLeft">
                            <span id="open4" onClick={open4} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>MET SEAM</span>
                 
                            </li>
                            <li  className="animate__animated animate__fadeInLeft">
                            <span id="open5" onClick={open5} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>Roof Sheets</span><br></br>
                           </li>
                            <li  className="animate__animated animate__fadeInLeft">
                            <span id="open6" onClick={open6} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>Purlin & Grits</span>
                 
                            </li>
                            <li  className="animate__animated animate__fadeInLeft">
                           
                            <span id="open7" onClick={open7} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>Accessories</span>
                            </li>
                            <li  className="animate__animated animate__fadeInLeft">
                            <span id="open8" onClick={open8} style={{backgroundColor:"#006fb4",border:" 1px solid green",padding: "10px 24px",fontWeight:"bold",cursor:"pointer",color:"white",width:"100%"}}>Services</span>
                      </li>
                    </ul>
                </div>
            </div>
        </div>
                            
                            
                                  <br></br>
                          <br></br></div>
                        <div className="col-md-8">
                            <div id="list1" className="row justify-content-center" >
                        <h2 className="animate__animated animate__bounceInDown">Steel Structures</h2>
                        <img style={{marginBottom:"10px"}}className="animate__animated animate__bounceIn"src="assets/images/product/Steel_Construction.jpg"/><br></br>
                        <p className="animate__animated animate__fadeInUp">The entire process of Industrial Building Construction, including the design, fabrication, supply, and erection of steel structures, is handled by our Structural Steel division, which also provides excellent Structural Steel Solutions. Globally, steel frames are progressively taking the place of concrete constructions, particularly in industrial steel buildings. This change is brought about by the fact that, in contrast to stiff concrete structures, structural steel construction enables a great deal of design and shape freedom.<br></br><br></br>

Steel is also more floor space-efficient than conventional concrete constructions, which makes it perfect for industrial building construction projects with restricted area. Since Industrial Steel Buildings can be prefabricated off-site and brought ready on-site, hence optimising the project timeline, the use of steel buildings can also greatly cut construction time.

</p>
<div className="row">
<div className="col-lg-7 col-md-6 col-sm-7 col-xs-12">
                <h4 style={{color:"rgb(0, 111, 180)"}}>Fabrication of Hot Rolled Steel Sections</h4>
                <div >
                  <ul style={{listStyleType:"square"}}>
                    <li className=" reveal fade-zoomin"> Universal Beam (UB) / Universal Columns (UC)</li>
                    <li className=" reveal fade-zoomin"> European Section – HE A / HE B</li>
                    <li className=" reveal fade-zoomin"> Indian Sections - ISMB / ISMC</li>
                    <li className=" reveal fade-zoomin"> CHS – Circular Hollow Sections</li>
                    <li className=" reveal fade-zoomin"> RHS – Rectangular Hollow Sections</li>
                    <li className=" reveal fade-zoomin" > PFC – Parallel Flange Channels</li>
                    <li className=" reveal fade-zoomin"> W- Wide Flange Beams</li>
                    <li className=" reveal fade-zoomin"> Equal / Unequal Angles</li>
                    <li className=" reveal fade-zoomin"> ISMB – Beams</li>
                    <li className=" reveal fade-zoomin"> ISMC – Channel</li>
                  </ul>
                </div>
              </div>
<div className="col-lg-5 col-md-6 col-sm-7 col-xs-12">
                <h4 style={{color:"rgb(0, 111, 180)"}}>Structural Steel - Applications</h4>
                <div >
                  <ul style={{listStyleType:"square"}}>
                  <li className=" reveal fade-zoomin">Onshore Oil &amp; Gas</li>
                    <li className=" reveal fade-zoomin"> Petrochemical</li>
                    <li className=" reveal fade-zoomin"> Power Projects</li>
                    <li className=" reveal fade-zoomin"> Desalination Projects</li>
                    <li className=" reveal fade-zoomin" > Heavy Industries</li>
                    <li className=" reveal fade-zoomin"> Hi-Rise Buildings</li>
                    <li className=" reveal fade-zoomin"> Airport Projects</li>
                    <li className=" reveal fade-zoomin"> Special Projects</li>
                  </ul>
                </div>
              </div>
</div>
                        {/* <div className="col-lg-12 col-12">
                            <article>
                                <div className={`shop-product-wrap row justify-content-center ${GridList ? "grid" : "list"}`} >
                                    {
                                    ProductList1.map((val, i) => (
                                        
                                        <div className="col-lg-4 col-md-6 col-12" key={i}>
                                            <div className="product-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <span style={{fontFamily:"Impact"}} onClick={() => handleSort(`${val.imgAlt}`)}>{`${val.imgAlt}`} </span>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5>{val.title}</h5>
                                                </div>
                                            </div>
                                            <div className="product-list">
                                            <div class={val.imgAlt} id={val.imgAlt} style={{display:"none"}}>
                                                  <div className="row">
                                                  <div className="col-md-6">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></div>
                                                  <div className="col-md-6"></div>
                                                  </div>
                                                  <a href="#" onClick={() => hideSort(`${val.imgAlt}`)}>Close</a>
                                            </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </article>
                        </div> */}
                    </div>
                    <div id="list2"className="row justify-content-center" >
                        <h2 className="animate__animated animate__bounceInDown">LGS Fab</h2>
                        <img style={{marginBottom:"10px"}}className="animate__animated animate__bounceIn"src="assets/images/product/LGS.jpg"/><br></br>
                      
                        <p className="animate__animated animate__fadeInUp">
                        Light gauge steel buildings can be used for a variety of building types since they are fireproof. Steel is covered with fire-rated sheeting to increase its resistance to fire. Moreover, steel is a great substitute for wood in termite-prone areas because it doesn't decay, disintegrate, or distort.
                        </p>
                    </div>
                    <div id="list3"className="row justify-content-center"  >
                        <h2 className="animate__animated animate__bounceInDown">Solar Structures</h2>
                        <img style={{marginBottom:"10px"}}className="animate__animated animate__bounceIn"src="assets/images/page/solar.png"/><br></br>
                      
                        <p className="animate__animated animate__fadeInUp">
                        We have a strong in-house design team to undertake any type of roll forming products for various engineering applications. One such recent development is supply of cold roll formed channels and angles, pre-galvanised or hot dip galvanised finish for SOLAR STRUCTURES. We have supplied to various customers to the tune of 125 MW power plants.
                           </p>
                         <p className="animate__animated animate__fadeInUp">With the wider presence in terms of manufacturing facilities and infrastructure, we can manufacture and supply of cold rolled sections like C, Z, U &amp; L with required length and punching pattern from 0.9mm to 3.2mm thickness and C section of 60x30mm and 30x30mm. L-angle with tolerance as per the relevant Indian and International standards.</p>
                    </div>
                    <div id="list4"className="row justify-content-center" >
                        <h2 className="animate__animated animate__bounceInDown">Met Seam 360 Profile</h2>
                       <p className="animate__animated animate__fadeInUp">Metco’s Met Seam 360 Metal Roof Panel Systems are Extremely Durable and they are Roll Formed and Mechanically Seamed on the Project site.
Met Seam 360 Seam Lock Panels are a Roof System that is Secured to the Roof Purlin with Clips Allowing for Roofing Panel Thermal Movement.
Mechanically Seamed Panels These Roofing Systems Require Floating Clips to Secure them and must be Crimped Together by Hand and Machine, providing screwless and leak tight roofing system. 
Panel Width - 480mm</p>
                    </div>
                    <div id="list5" className="row justify-content-center" >
                    <h2 className="animate__animated animate__bounceInDown">ROOFING SHEETS</h2>
                  
                        <div className="col-lg-12 col-12">
                            <article style={{overflowY: 'scroll',overflowX: 'hidden',height:'500px'}}>
                              
                                <div className={`shop-product-wrap row justify-content-center ${GridList ? "grid" : "list"}`} >
                                    {ProductList3.map((val, i) => (
                                        <div className="col-lg-6 col-md-6 col-12" key={i}>
                                            <div className="product-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <span style={{fontFamily:"Impact"}}>{`${val.imgAlt}`}</span>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5>{val.title}</h5>
                                                </div>
                                            </div>
                                            <div className="product-list-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <a href="#"><i className="icofont-eye"></i></a>
                                                        <a href="#"><i className="icofont-heart"></i></a>
                                                        <a href="#"><i className="icofont-cart-alt"></i></a>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5>{val.title}</h5>
                                                    <h6>{val.price}</h6>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </article>
                        </div>
                    </div>
                    <div id="list6"className="row justify-content-center" >
                        <h2 className="animate__animated animate__bounceInDown">PURLINS & GRITS</h2>
                        <p className="animate__animated animate__fadeInUp"><span style={{color:"#006FB4",fontWeight:"bold",fontSize:"35px"}}>C PURLIN</span><br></br><br></br>

C purlins are built to shape a building shell structure’s walls and floor joists, making them also suitable for supporting the beams required for flooring. C segment purlins with door mounting, roof clipping, window trimming, parapet rails, and side rails.<br></br><br></br>

<span style={{color:"#006FB4",fontWeight:"bold",fontSize:"35px"}}>Z PURLIN</span><br></br><br></br>

Z purlins are horizontal beams that are built to shape a building shell structure’s roof and wall joists.<br></br><br></br>
They sit between the roofing sheets and the building, acting as sheet support to ensure that it is firmly attached and secure in place.<br></br><br></br>
<span style={{color:"#006FB4",fontWeight:"bold",fontSize:"35px"}}>M PURLIN</span><br></br><br></br>


M Purlin are accurately roll-formed from high-strength steel to provide an efficient, lightweight, and economical roofing and cladding support system for structural steel buildings. Sigma section/purlins are quick to install and suitable for both insulated and non-insulated roofs and walls.

</p>
                        <div className="col-lg-12 col-12">
                            <article>
                              
                                <div className={`shop-product-wrap row justify-content-center ${GridList ? "grid" : "list"}`} >
                                    {ProductList2.map((val, i) => (
                                        <div className="col-lg-4 col-md-6 col-12" key={i}>
                                            <div className="product-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <span style={{fontFamily:"Impact"}}>{`${val.imgAlt}`}</span>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5>{val.title}</h5>
                                                </div>
                                            </div>
                                            <div className="product-list-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <a href="#"><i className="icofont-eye"></i></a>
                                                        <a href="#"><i className="icofont-heart"></i></a>
                                                        <a href="#"><i className="icofont-cart-alt"></i></a>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5>{val.title}</h5>
                                                    <h6>{val.price}</h6>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </article>
                        </div>
                    </div>
                    <div id="list7"className="row justify-content-center"  >
                    <h2 className="animate__animated animate__bounceInDown">ACCESSORIES</h2>
               

                        <div className="col-lg-12 col-12">
                            <article style={{overflowY: 'scroll',overflowX: 'hidden',height:'500px'}}>
                              
                                <div className={`shop-product-wrap row justify-content-center ${GridList ? "grid" : "list"}`} >
                                    {ProductList4.map((val, i) => (
                                        <div className="col-lg-3 col-md-6 col-12" key={i}>
                                            <div className="product-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <span style={{fontFamily:"Impact"}}>{`${val.imgAlt}`}</span>
                                                    </div>
                                                </div>
                                                {/* <div className="product-content">
                                                    <h5>{val.title}</h5>
                                                </div> */}
                                            </div>
                                            <div className="product-list-item">
                                                <div className="product-thumb">
                                                    <div className="pro-thumb">
                                                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                    </div>
                                                    <div className="product-action-link">
                                                        <a href="#"><i className="icofont-eye"></i></a>
                                                        <a href="#"><i className="icofont-heart"></i></a>
                                                        <a href="#"><i className="icofont-cart-alt"></i></a>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <h5>{val.title}</h5>
                                                    <h6>{val.price}</h6>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </article>
                        </div>
                    </div>
                    <div id="list8"className="row justify-content-center" >
                        <h2 className="animate__animated animate__bounceInDown">Services</h2>
                        <div className="col-lg-12 col-12">
                            <article>
                            Metco offers engineering, procurement, production and incorporated undertaking control offerings from idea to commissioning with inside the area of Heavy, Medium and Light Structural Work with each non-technological and technological structures.
                            </article>
                        </div>
                    </div>
                    <div id="list9" className="row justify-content-center" >
                    <h2 className="animate__animated animate__bounceInDown">Pre Engineered Building (PEB)</h2>
                        <img style={{marginBottom:"10px"}}className="animate__animated animate__bounceIn"src="assets/images/product/PEB.jpg"/><br></br>
                        <p className="animate__animated animate__fadeInUp">Our objective is to have an increased presence in the metal building systems industry.

To meet Challenges in the supply of high quality standard metal buildings, as Pre Engineered Building (PEB).</p>
<div className="row">
<div className="col-lg-12 col-md-6 col-sm-7 col-xs-12">
                <div >
                  <ul style={{listStyleType:"square"}}>
                    <li className=" reveal fade-zoomin"> We have upgraded our manufacturing facilities, multi-locations in India.</li>
                    <li className=" reveal fade-zoomin">We have installed world class equipments, fully automated beamline facility.</li>
                    <li className=" reveal fade-zoomin">Perfect Welding process, flawless edge corners and precise designed angles are taken care in our manufacturing systems.</li>
                    <li className=" reveal fade-zoomin">Total quality control and higher levels of customer satisfaction.</li>
                    <li className=" reveal fade-zoomin">To ensure enduring qualities and above all values for money to our most valued customers.</li>
                    <li className=" reveal fade-zoomin">Dedicated team of Engineers and top management.</li>
                  </ul>
                </div>
              </div>
</div>
                
                    </div></div>
                    </div>
                    
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default ShopPage;

import { Link } from "react-router-dom";
const subTitle = "METCO";
const title = "Our Services";

let categoryItemList = [
    {
        imgUrl: 'assets/images/category/icon/buildings.png',
        imgAlt: 'category',
        title: 'Pre Engineering Building',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/category/icon/stairs.png',
        imgAlt: 'category',
        title: 'Steel & High Structures',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/category/icon/bridge.png',
        imgAlt: 'category',
        title: 'Steel Bridges',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/category/icon/construction.png',
        imgAlt: 'category',
        title: 'Modular Building & Erection',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/category/icon/roof.png',
        imgAlt: 'category',
        title: 'Roofing System & Accessories',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
    {
        imgUrl: 'assets/images/category/icon/solar.png',
        imgAlt: 'category',
        title: 'Solar Structures',
        desc: 'Like graphic design, business anaytics coding and much more',
    },
]

const CategoryTwo = () => {
    return (
        <div className="category-section padding-tb section-bg style-3">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row g-4 justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
                            {categoryItemList.map((val, i) => (
                                <div className="col" key={i}>
                                    <div className="category-item text-center reveal bigEntrance">
                                        <div className="category-inner">
                                            <div className="category-thumb">
                                                <img style={{width:"50%"}}src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="category-content">
                                               <h4>{val.title}</h4>
                                                {/* <p>{val.desc}</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
    );
}
 
export default CategoryTwo;
import { Component, Fragment } from "react";
import Footer from "../component/layout/footer-3";
import Header from "../component/layout/header-3";
import PageHeader from "../component/section/career";
import GoogleMap from "../component/sidebar/googlemap";
import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import axios  from "axios";

import FloatImg from "../component/section/float-icon";

const conSubTitle = "METCO";
const conTitle = "Careers";
const btnText = "Submit Profile";



function ContactPage() {
    const [state,setState]=useState({name:'',email:'',phone:'',address:'',comment:'', file: ''});
    
    const handleFileChange = (e) => {
       
        setState({...state, file: e.target.files[0]})
      }
    
    async function SendData(){
        let formData = new FormData()
        formData.append('file', state.file);
        formData.append('name', state.name);
        formData.append('email', state.email);
        formData.append('phone', state.phone);
        formData.append('address', state.address);
        formData.append('comment', state.comment);
    
        try{
        const response=await axios.post("https://api.metcoroof.com/Career/Send", formData);
        console.log(response);
        if(response.data.isOk){
            alert(response.data.successMessage);
            setState({...state,name:'', phone:'', email:'', address:'',comment:'', file:''})
        }
        else{
            alert(response.data.errorMessage);
        }
        }
        catch(ex){
            console.log(ex);
        } 
     }
     return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="contact-section padding-tb">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{conSubTitle}</span>
                        <h2 className="title">{conTitle}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="contact-form">
                            <div className="form-group  w-100">
                                <input type="text" name="name" placeholder="Your Name *"  value={state.name} onChange={(ca)=>setState({...state,name:(ca.target.value)})} />
                            </div>
                            <div className="form-group  w-100">
                                <input type="text"name="email" placeholder="Your Email *" value={state.email} onChange={(ca)=>setState({...state,email:(ca.target.value)})}/>
                            </div>
                            <div className="form-group  w-100">
                                <input type="text" name="number" placeholder="Mobile Number *" value={state.phone} onChange={(ca)=>setState({...state,phone:(ca.target.value)})}/>
                            </div>
                            <div className="form-group  w-100">
                                <input type="text" name="subject" placeholder="Your Address *" value={state.address} onChange={(ca)=>setState({...state,address:(ca.target.value)})}/>
                            </div>
                            <div className="w-100" style={{marginBottom:"20px"}}>
                            <h4>Attached Resume Here</h4>
                                <input type="file" name="subject" placeholder="Upload File *" accept="application/pdf" onChange={handleFileChange} />
                                Attach .pdf files only (Max of 3MB)
                            </div>
                            <div className="form-group w-100">

                                <textarea rows="8" type="text" placeholder="Tell something interesting about you" value={state.comment} onChange={(ca)=>setState({...state,comment:(ca.target.value)})}></textarea>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button className="lab-btn" onClick={()=>SendData()}>SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
    }
  export default ContactPage;
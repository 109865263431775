

import { Link, NavLink } from "react-router-dom";

const title = "About Us";


const cscContentList = [
    {
        link: '/our-team',
        left: 'Our Team',
    },
    {
        link: '/vision-mission',
        left: 'Vision & Mission',
    },
    {
        link: '/core-values',
        left: 'Core Values',
    },
    {
        link: '/quality-policy',
        left: 'Quality Policy',
    },
]


const CourseSideCetagory = () => {
    return (
        <div className="course-side-cetagory">
            <div className="csc-title" style={{backgroundColor:"white"}}>
                <h5 className="mb-0 animate__bounceInDown animate__animated" style={{color:"rgb(82, 74, 120)",fontSize:"35px"}}><i className="icofont-double-right"></i>{title}</h5>
            </div>
            <div className="csc-content">
                <div className="csdc-lists">
                    <ul className="lab-ul">
                        {cscContentList.map((val, i) => (
                            <li key={i} className="animate__bounceInLeft animate__animated">
                                <div className="csdc-left"><NavLink to={val.link} className="login">{val.left}</NavLink></div>
                                <div className="csdc-right"><NavLink to={val.link} className="login"><i className="icofont-double-right"></i></NavLink></div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
 
export default CourseSideCetagory;
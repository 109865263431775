import { Fragment, useState } from "react";
import { Link, NavLink } from "react-router-dom";


const HeaderThree = () => {
    
    const [menuToggle, setMenuToggle] = useState(false);
	const [socialToggle, setSocialToggle] = useState(false);
	const [headerFiexd, setHeaderFiexd] = useState(false);
	const [searchTrigger, setSearchTrigger] = useState(false);

	window.addEventListener("scroll", () => {
		if (window.scrollY > 200) {
			setHeaderFiexd(true);
		} else {
			setHeaderFiexd(false);
		}
	});
    
    return (
        <Fragment>
            <header className={`header-section style-3 ${headerFiexd ? "header-fixed fadeInUp" : ""}`} style={{position: "fixed",
    border: "none"}}>
                {/* <div className={`header-top ${socialToggle ? "open" : ""}`}>
                    <div className="container">
                        <div className="header-top-area">
                            <div className="header-top-contact">
                                <a>+91 44 42024747 / 4848 / 4949</a>
                                <a>contact@metcoroof.in</a>
                            </div>
                            <div className="header-top-reglog">
                                <a href="#" className="lab-btn"><span id="myBtn" >Enquiry</span></a>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="header-bottom" style={{backgroundColor:"transparent"}}>
                    <div className="container">
                        <div className="header-wrapper">
                            <div className="logo-search-acte">
                                <div className="logo">
                                    <Link to="/"><img src="assets/images/logo/01.png" style={{height:120}}alt="logo" /></Link>
                                </div>
                            </div>
                            <div className="menu-area">
                                <div className="menu" id="menu">
                                    <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                                        
                                    <li><NavLink to="/home">Home</NavLink></li>
                                        <li className="menu-item-has-children">
                                            <a href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,0">About Us</a>
                                            <ul className="lab-ul dropdown-menu">
                                                <li><NavLink to="/our-team">Our Team</NavLink></li>
                                                <li><NavLink to="/core-values">Core Values</NavLink></li>
                                                <li><NavLink to="/vision-mission">Vision And Mission</NavLink></li>
                                                <li><NavLink to="/quality-policy">Quality Policy</NavLink></li>
                                            </ul>
                                        </li>
                                    {/* <li><NavLink to="/PEBSteelStructurals">PEB/Steel Structurals</NavLink></li>
                                    <li><NavLink to="/LGS">LGS</NavLink></li>
                                    <li><NavLink to="/Solar">Solar Structures</NavLink></li>
                                   
                                    <li><NavLink to="/Product">Products</NavLink></li>*/}
                                       <li><NavLink to="/ProductsAndServices">Products & Services</NavLink></li>
                                    <li><NavLink to="/Infra">Infra</NavLink></li> 
                                    <li><NavLink to="/Projects">Projects</NavLink></li> 
                                    <li><NavLink to="/Career">Careers</NavLink></li>
                                        <li><NavLink to="/contact">Contact Us</NavLink></li>
                                        <img style={{height:"60px",zIndex:"999",marginTop:"10px",float:"left"}} src="assets/images/certificate/c-1.png" />
  <img style={{height:"60px",zIndex:"999",marginTop:"10px",float:"left"}} src="assets/images/certificate/c-2.png" />
  <img style={{height:"60px",zIndex:"999",marginTop:"10px",float:"left"}} src="assets/images/certificate//c-3.png" />
                                    </ul>
                                </div>

                                {/* <div className="search-icon" onClick={() => setSearchTrigger(!searchTrigger)}>
                                    <i className="icofont-search"></i>
                                </div> */}

                                <div className={`header-bar d-lg-none ${menuToggle ? "active" : "" }`} onClick={() => setMenuToggle(!menuToggle)}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>

                                <div className="ellepsis-bar d-lg-none" onClick={() => setSocialToggle(!socialToggle)}>
                                    <i className="icofont-info-square"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <div className={`menu-search-form ${searchTrigger ? "open" : ""}`}>
                <div className="search-close" onClick={() => setSearchTrigger(!searchTrigger)}></div>
                <form>
                    <input type="text" name="search" placeholder="Search here..." />
                    <button type="submit"><i className="icofont-search"></i></button>
                </form>
            </div>
        </Fragment>
    );
}
 
export default HeaderThree;





const subTitle = "Best Solution for your House ";
const title = "Why You Should use our products";
const desc = "Roof tiling can offer owners with a long lasting and aesthetically excellent exterior. Some clay roofs can remaining for a century, and plenty of require handiest a minimum quantity of maintenance.";


const chooseList = [
    {
        text: 'Longevity',
    },
    {
        text: 'Low Maintenance',
    },
    {
        text: 'Durability',
    },
    {
        text: 'Insulation',
    },
    {
        text: 'Weather resistance',
    },
    {
        text: 'Aesthetics',
    },
    {
        text: 'Cost Effecient',
    },
]


const Choose = () => {
    return (
        <div className="choose-section padding-tb" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12">
                        <div className="section-header">
                            <span className="subtitle yellow-color fade-zoomin reveal">{subTitle}</span>
                            <h2 className="title fade-zoomin reveal">{title}</h2>
                            <p className="fade-zoomin reveal">{desc}</p>
                            <ul className="lab-ul">
                                {chooseList.map((val, i) => (
                                    <li className=" reveal fade-zoomin"key={i}><i className="icofont-circled-right"></i>{val.text}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12">
                        <div className="section-wrapper">
                            <div className="choose-thumb">
                               <img src="assets/images/banner/banner-3.jpg" style={{width:"100%"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Choose;
import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import QuickLink from "../component/sidebar/corporate-link";
import Student from "../component/section/student";
import Testbanner from "../component/section/banner-z";
import FloatImg from "../component/section/float-icon";

import FooterTwo from "../component/layout/footer-3";
import BannerFive from "../component/section/philosophy-banner";


const subTitle = "About Our METCO";
const title = "Good Quality Products And Excellent Services";

const year = "24+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row ">
                    <div className="col-md-4">
                    <QuickLink/>
                        </div>
                        <div className="col-md-8">
                        <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4 className="fade-top reveal"style={{color:"red"}}>-OUR PHILOSOPHY</h4>
                                            <h3 className="fade-top reveal">INNOVATION. INTEGRITY. EXPERIENCE.</h3>
                                            <h4 className="fade-top reveal">Innovation. Our Keystone.</h4>
                                            <p className="fade-zoomin reveal">A culture of innovation allows METCO to realize benefits in every area – processes, workflow, technology, mechanization or IT automation. Needless to say, the drive for innovation is backed by meticulous planning that ensures cost estimates, quality standards and time commitments are strictly adhered to.</p>
                                            <h4 className="fade-top reveal">Integrity. Our Foundation.</h4>
                                            <p className="fade-zoomin reveal">We remain true to our founding values of quality, honesty and hard work. We maintain high ethical standards. METCO is consistently associated with the high standards of service, quality, personal attention to clients, and integrity. METCO is sincere and committed in honouring all contracts.</p>
                                            <h4 className="fade-top reveal">Experience. Our Framework.</h4>
                                            <p className="fade-zoomin reveal">A culture of innovation allows METCO to realize benefits in every area – processes, workflow, technology, mechanization or IT automation. Needless to say, the drive for innovation is backed by meticulous planning that ensures cost estimates, quality standards and time commitments are strictly adhered to.</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 
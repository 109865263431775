import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import QuickLink from "../component/sidebar/corporate-link";
import Student from "../component/section/student";
import Testbanner from "../component/section/banner-z";
import FloatImg from "../component/section/float-icon";

import FooterTwo from "../component/layout/footer-3";
import BannerFive from "../component/section/quality-banner";


const subTitle = "About Our METCO";
const title = "Good Quality Products And Excellent Services";

const year = "24+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row ">
                    <div className="col-md-4">
                    <QuickLink/>
                        </div>
                        <div className="col-md-8">
                        <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4 className="animate__heartBeat animate__animated"style={{color:"red"}}>-CORE VALUES</h4>
                                            <h3 className="animate__lightSpeedInRight  animate__animated">Passion</h3>
                                            <p className="animate__zoomIn animate__animated">
Our meticulous dedication to improve the standard and look of our product has reaped us with this two decades of rich experience and expertise in steel industry. </p>
<h3 className="animate__lightSpeedInRight  animate__animated">Quality</h3>

<p className="animate__zoomIn animate__animated">
Our committed and un-compromised policy towards quality and timely deliveries has already put us in good stead,we continue to serve our customers with the same motto and seek their patronage and suggestions for creating a win-win situation.</p>

<h3 className="animate__lightSpeedInRight  animate__animated">Customer Satisfaction</h3>

<p className="animate__zoomIn animate__animated">
Metco Roof believes and practice that customer is always right and offer to choose from the whole range of combinations available with us to suit their requirements. We also share our knowledge and experience with our customers so that together we can build up the optimum best for our valued customers.
</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 
import React,{useState} from 'react';
import axios from 'axios';

const FloatingImg = () => {
    const [contactState,setcontactState]=useState({name:'',email:'',phone:'',address:'',comment:''});
    async function SendEnquiryData(){
        console.log(contactState);
        var jasonData = {
            name:contactState.name,
            email:contactState.email,
            phone:contactState.phone,
            address:contactState.address,
            comment:contactState.comment,            
        };

        try{
            const response=await axios.post("https://api.metcoroof.com/Career/Enquiry", jasonData);
            console.log(response);
            if(response.data.isOk){
                alert(response.data.successMessage);
                setcontactState({...contactState,name:'', phone:'', email:'', address:'',comment:''})
            }
            else{
                alert(response.data.errorMessage);
            }
            }
            catch(ex){
                console.log(ex);
            } 
     }
  const open = () => {
    document.getElementById("myModal").style.display="block";
  }
  const close = () => {
    document.getElementById("myModal").style.display="none";
  }
    return (
      <div>
      <div style={{width:"auto",position: "fixed",zIndex:"998",left:"10px",bottom:"100px",borderRadius:"50%"}}>
  {/* <img style={{height:"150px",zIndex:"999"}} src="assets/images/25.png" /> */}
      <div style={{width:"auto",position: "fixed",zIndex:"998",right:"10px",bottom:"40%"}}>
  <img style={{height:"80px",zIndex:"999",'cursor':'pointer'}} src="assets/images/enquiry-alt.png" onClick={open}/><br></br>
 <a href='https://wa.me/918056233334' target='blank' style={{'cursor':'pointer'}}><img style={{height:"80px",zIndex:"999",marginTop:"10px",float:"right"}} src="assets/images/whatsapp-alt.png" /></a> </div>
  
 
      
      </div>
      <div id="myModal" className="PopModal">
  <div className="PopModal-content">
    <span className="close" onClick={close}>&times;</span>
    <div className="">
                <div className="container">
                    <div className="text-center">
                        <h2 className="title">Enquiry</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="contact-form" style={{width:"100%"}}>
                            <div className="form-group">
                                <input value={contactState.name} onChange={(ev)=>setcontactState({...contactState,name:(ev.target.value)})}
                                    type="text"
                                    name="name"
                                    placeholder="Your Name *"
                                />
                            </div>
                            <div className="form-group">
                                <input value={contactState.email} onChange={(ev)=>setcontactState({...contactState,email:(ev.target.value)})}
                                    type="text"
                                    name="email"
                                    placeholder="Your Email *"
                                />
                            </div>
                            <div className="form-group">
                                <input value={contactState.phone} onChange={(ev)=>setcontactState({...contactState,phone:(ev.target.value)})}
                                    type="text"
                                    name="number"
                                    placeholder="Mobile Number *"
                                />
                            </div>
                            <div className="form-group">
                                <input value={contactState.address} onChange={(ev)=>setcontactState({...contactState,address:(ev.target.value)})}
                                    type="text"
                                    name="subject"
                                    placeholder="Your Address *"
                                />
                            </div>
                            <div className="form-group w-100">

                                <textarea value={contactState.comment} onChange={(ev)=>setcontactState({...contactState,comment:(ev.target.value)})}
                                    rows="8" 
                                    type="text"
                                    placeholder="Tell something interesting about you"
                                ></textarea>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button className="lab-btn" onClick={()=>SendEnquiryData()}><span>SEND</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </div>
  </div>
</div>
    );
}
 

export default FloatingImg;
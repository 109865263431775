import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import QuickLink from "../component/sidebar/corporate-link";
import Student from "../component/section/student";
import Testbanner from "../component/section/banner-z";
import FloatImg from "../component/section/float-icon";

import FooterTwo from "../component/layout/footer-3";
import BannerFive from "../component/section/about-banner";


const subTitle = "About Our METCO";
const title = "Good Quality Products And Excellent Services";

const year = "24+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row ">
                    <div className="col-md-4">
                    <QuickLink/>
                        </div>
                        <div className="col-md-8">
                        <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4 className="fade-top reveal" style={{color:"red"}} >-OVERVIEW</h4>
                                            <h3 className="fade-top reveal">SIX DECADES OF EXCELLENCE
IN THE BUSINESS OF CIVIL ENGINEERING
AND CONSTRUCTION SINCE 1948</h3>
                                            <p className="fade-zoomin reveal">Established in 1947, and incorporated in 1948, as K.S. Venkatraman & Co. Pvt. Ltd., we have, right from our inception, built our reputation of engineering excellence and quality on the foundation of Innovation, Integrity & Experience.<br></br><br></br>

Throughout the years, KSV has established a standing for high quality construction, delivering customer satisfaction, maintaining financial stability and achieving project excellence.<br></br><br></br>

As one of the leading contractors in the state and certified as Class I Engineering Contractors by government departments, we have executed prestigious projects for major organizations, working with leading architects of the country. Our credentials have been acknowledged by organizations and institutions, both in private and government sectors.<br></br><br></br>

KSV’s family spirit and values have been the foundation of the company’s success for more than 60 years. These values are evident today, deeply embedded in the culture of the company, and provide the foundation for successive generations of leadership.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 
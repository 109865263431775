import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer-3";
import Header from "../component/layout/header-3";
import PageHeader from "../component/section/Projects";
import Pagination from "../component/sidebar/pagination";
import PopularPost from "../component/sidebar/popular-post";
import Rating from "../component/sidebar/rating";
import Search from "../component/sidebar/search";
import ShopCategory from "../component/sidebar/shop-category";
import Tags from "../component/sidebar/tags";
import React from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

import FloatImg from "../component/section/float-icon";
const showResult = "Showing 01 - 12 of 139 Results";


let Projects = [
    {
        imgUrl: 'assets/images/infra/Press Brake Machine.jpg',
        imgAlt: 'Press Brake Machine',
        title: 'CLEAR SPAN',
        popId:'pop-1',
        popAlt:'#pop-1',
        prev:'#pop-24',
        next:'#pop-2',
        price: '$200.00',
        desc1: 'Bed Length  - 6m',
        desc2: '',
    },
    {
        imgUrl: 'assets/images/infra/Profiled Down Take.jpg',
        imgAlt: 'Profiled Down Take',
        title: 'CRANE BUILDING',
        popId:'pop-2',
        popAlt:'#pop-2',
        prev:'#pop-1',
        next:'#pop-3',
        price: '$200.00',
        desc1: 'Dimension: 75mmX125mm',
        desc2: 'Various bending possibilities according to client requirement',
    },
    {
        imgUrl: 'assets/images/infra/Heavy Duty Shearing Machine.jpg',
        imgAlt: 'Heavy Duty Shearing Machine',
        title: 'CURVED MONOSLOPE',
        popId:'pop-3',
        popAlt:'#pop-3',
        prev:'#pop-2',
        next:'#pop-4',
        price: '$200.00',
        desc1: 'Upto 20mm Shearing Capacity',
        desc2: ' ',
    },
    {
        imgUrl: 'assets/images/infra/Semi Plate Processor Machine.jpg',
        imgAlt: 'Semi Plate Processor Machine',
        title: 'CURVED RAFTER',
        popId:'pop-4',
        popAlt:'#pop-4',
        prev:'#pop-3',
        next:'#pop-5',
        price: '$200.00',
        desc1: 'Maximum Workable thickness - 60mm',
        desc2: 'Bed Length – 12m Accuracy – 0.03mm',
    },
    {
        imgUrl: 'assets/images/infra/Pull Through Welding Machine.jpg',
        imgAlt: 'Pull Through Welding Machine (PTW Machine)',
        title: 'CURVED RAFTER 1',
        popId:'pop-5',
        popAlt:'#pop-5',
        prev:'#pop-4',
        next:'#pop-6',
        price: '$200.00',
        desc1: 'Max Web Width – upto 1800mm',
        desc2: 'Max Thickness – Upto 30mm',
    },
    {
        imgUrl: 'assets/images/infra/CZM BRADBURY-USA Purlin Machine.jpg',
        imgAlt: 'C/Z/M BRADBURY- USA Purlin Machine',
        title: 'G+1 BUILDING',
        popId:'pop-6',
        popAlt:'#pop-6',
        prev:'#pop-5',
        next:'#pop-7',
        price: '$200.00',
        desc1: 'Max Web – 300mm',
        desc2: 'Max Thickness – 3.15mm',
    },
    {
        imgUrl: 'assets/images/infra/Sheet Metal Profile MET-RIB Machine.jpg',
        imgAlt: 'Sheet Metal Profile MET-RIB Machine (Taiwan Make)',
        title: 'LEAN TO',
        popId:'pop-7',
        popAlt:'#pop-7',
        prev:'#pop-6',
        next:'#pop-8',
        price: '$200.00',
        desc1: 'Width Thickness - 0.8mm',
        desc2: '',
    },
    {
        imgUrl: 'assets/images/infra/Sheet Metal Folder Machine.jpg',
        imgAlt: 'Sheet Metal Folder Machine',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Origin – Germany Maximum Thickness – 3.15mm ',
        desc2: 'Material – HR, GI, SS, Aluminium, Polycarbonate Possibilities – Any desired profile by client Bed Length – upto 12m',
    },
    {
        imgUrl: 'assets/images/infra/Sheet Metal Profile MET-FIN Machine.jpg',
        imgAlt: 'Sheet Metal Profile MET-FIN Machine (Taiwan Make)',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Width – 1120mm',
        desc2: 'Thickness – 0.80mm',
    },
    {
        imgUrl: 'assets/images/infra/Sheet Metal Profile MET-STRONG Machine.jpg',
        imgAlt: 'Sheet Metal Profile MET-STRONG Machine (Taiwan Make)',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Width – 1100mm',
        desc2: 'Thickness – 0.80mm',
    },
    {
        imgUrl: 'assets/images/infra/Seamlock.jpg',
        imgAlt: 'Seamlock',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Thickness - 0.50 to 0.80mm, Material – PPGL/Alu/SS',
        desc2: 'Profile Width – 480mm, Profile Depth – 75mm',
    },
    {
        imgUrl: 'assets/images/infra/Iron Worker.jpg',
        imgAlt: 'Iron Worker',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Capacity - 110 TON',
        desc2: '',
    },
    {
        imgUrl: 'assets/images/infra/SAW Welding Machine.jpg',
        imgAlt: 'SAW Welding Machine',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Capacity – 30 Dia',
        desc2: '',
    },
    {
        imgUrl: 'assets/images/infra/thread cutter.jpg',
        imgAlt: 'Thread Cutter',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Capacity – 30 Dia',
        desc2: '',
    },
    {
        imgUrl: 'assets/images/infra/EOT.jpg',
        imgAlt: 'EOT',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Capacity – 5TON, 10 TON, 20TON',
        desc2: '',
    },
    {
        imgUrl: 'assets/images/infra/band saw.jpg',
        imgAlt: 'Band Saw.',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Max Size – 200mm',
        desc2: '',
    },
    {
        imgUrl: 'assets/images/infra/Deck.jpg',
        imgAlt: 'Deck',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc1: 'Thickness – 0.8mm to 2mm',
        desc2: '',
    },
]


const ShopPage = () => {
    const [GridList, setGridList] = useState(true);
    
    return (
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="shop-page padding-tb">
                <div className="container">
        <h1>Our Infra</h1>
        <p>Metco’s cutting edge and advanced infrastructure including sophisticated cnc drilling machine, bending machine, shearing machine, plate processor, welding machine, pipe bending, ironworkers, automated beam welding machine lines, beam leveller, semi beam welding machine and blasting and painting yard. <br></br><br></br>
Metco takes pride in the use of top-of-the-line manufacturing equipment at a fully integrated, state-of-the-art CNC production facility at Puducherry and Sriperumbudur
equipped with a capacity of 24,000 MT per annum
Built up. <br></br><br></br>

Our Design facility is located near Chennai, Highly experienced and qualified design team. The team uses robust engineering platforms to offer design and detailing solutions for our buildings.</p>
      </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default ShopPage;
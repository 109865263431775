import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import QuickLink from "../component/sidebar/corporate-link";
import Student from "../component/section/student";
import Testbanner from "../component/section/banner-z";
import FloatImg from "../component/section/float-icon";

import FooterTwo from "../component/layout/footer-3";
import BannerFive from "../component/section/green-banner";


const subTitle = "About Our METCO";
const title = "Good Quality Products And Excellent Services";

const year = "24+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row ">
                    <div className="col-md-4">
                    <QuickLink/>
                        </div>
                        <div className="col-md-8">
                        <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4 className="fade-top reveal"style={{color:"red"}}>-OVERVIEW</h4>
                                            <h3 className="fade-top reveal">GREEN & SUSTAINABLE CONSTRUCTION</h3>
                                            <p className="fade-zoomin reveal">METCO has long been a good steward of the environment. We’ve been creating high performance buildings for decades; well before LEED or other Green standards became a critical designation for the built environment in our communities.<br></br><br></br>

Today, in association with the Indian Green Building Council’s LEED program (Leadership in Energy and Environmental Design), METCO has built certified Platinum and Gold Rated Green buildings, through sustainable design ensuring effective and efficient utilization of natural energy, water, and raw materials; preventing environmental degradation caused by facilities and infrastructure throughout their life cycle; and creating built environments that are livable, comfortable, safe and productive.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 
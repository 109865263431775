
import CountUp from 'react-countup';
import { Link } from "react-router-dom";

const title = "METCO";
const btnText = "Apply  Now";


const countList = [
    {
        iconName: 'icofont-thumbs-up',
        count: '2000',
        text: 'Projects',
    },
    {
        iconName: 'icofont-users',
        count: '500',
        text: 'Clients',
    },
    {
        iconName: 'icofont-star',
        count: '20',
        text: 'Years Experts',
    },
    {
        iconName: 'icofont-worker',
        count: '100',
        text: 'Employees',
    },
]

const InstructorTwo = () => {
    return (
        <div className="instructor-section style-2 padding-tb"  style={{backgroundColor:"#a8d5e5"}}>
            <div className="container">
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-1 row-cols-md-2 row-cols-xl-3 align-items-center">
                        <div className="col">
                            {countList.map((val, i) => (
                                <div className="count-item slideExpandUp reveal" key={i} >
                                    <div className="count-inner">
                                        <div className="count-icon">
                                            <i className={val.iconName} style={{backgroundColor:"#006fb4"}}></i>
                                        </div>
                                        <div className="count-content">
                                            <h2><span className="count"><CountUp end={val.count} /></span><span>+</span></h2>
                                            <p>{val.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="col" >
                            <div className="instructor-content" >
                                <h2 className="title">{title}</h2>
                                <p className="fade-right reveal">
                                Metco Roof ™ Private Limited, started two decades back, with the commitment for offering world class products in Pre Engineered Building System complete in design, development,  manufacture and erection, tuned to national and international quality standards with consistency and customer satisfaction.<br></br><br></br>
METCO offers Colour Coated steel roofing as per customer’s choice. Steel roofing systems provide outstanding aesthetic appearance having better mechanical properties, with higher yield strength. They are cost effective, easy to maintain, eco-friendly and above all, the ultimate choice.<br></br><br></br>
At METCO, we manufacture profiled steel roofing sheets along with various accessories required for complete metal building solutions. Secondary structural members ranging from Z/C purlins and also sigma sections, adaptoble for Solar systems. We are specialised in Solar Panels mounting frames, duly punched and galvanized finishing.<br></br><br></br>
Our Manufacturing units are at Pondicherry, Sriperumubudur, Ranipet, Coimbatore, Gujarat & Banglore
</p> </div>
                        </div>
                        <div className="col">
                            <div className="instructor-thumb">
                                <img src="assets/images/logo/logo-frame.png" alt="education" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default InstructorTwo;
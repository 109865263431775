import { Component, Fragment } from "react";
import Footer from "../component/layout/footer-3";
import Header from "../component/layout/header-3";
import PageHeader from "../component/section/contact";
import GoogleMap from "../component/sidebar/googlemap";
import CourseTwo from "../component/section/course-2";
import React,{useState} from 'react';
import FloatImg from "../component/section/float-icon";
import axios  from "axios";
const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with Contact us";
const conTitle = "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Send our Message";


const contactList = [
    {
        imgUrl: 'assets/images/icon/01.png',
        imgAlt: 'contact icon',
        title: 'Registered Office Address',
        desc: '33, Chakrapani Street Extn, West Mambalam, Chennai, Tamilnadu.',
    },
    {
        imgUrl: 'assets/images/icon/02.png',
        imgAlt: 'contact icon',
        title: 'Phone number',
        desc: '+91 44 42024747',
        desc2: '+91 44 42024848 ',
        desc3:'+91 80 56233334',
    },
    {
        imgUrl: 'assets/images/icon/03.png',
        imgAlt: 'contact icon',
        title: 'Send email',
        desc: 'contact@metcoroof.in',
    },
    {
        imgUrl: 'assets/images/icon/04.png',
        imgAlt: 'contact icon',
        title: 'Our website',
        desc: 'www.metcoroof.in',
    },
]


const ContactPage = () => {
    const [contactState,setcontactState]=useState({name:'',email:'',phone:'',address:'',comment:''});
    
    async function SendContactData(){
        console.log(contactState);
        var jasonData = {
            name:contactState.name,
            email:contactState.email,
            phone:contactState.phone,
            address:contactState.address,
            comment:contactState.comment,            
        };

        try{
            const response=await axios.post("https://api.metcoroof.com/Career/Enquiry", jasonData);
            console.log(response);
            if(response.data.isOk){
                alert(response.data.successMessage);
                setcontactState({...contactState,name:'', phone:'', email:'', address:'',comment:''})
            }
            else{
                alert(response.data.errorMessage);
            }
            }
            catch(ex){
                console.log(ex);
            } 
       
     }
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="map-address-section padding-tb section-bg">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row flex-row-reverse">
                            <div className="col-xl-4 col-lg-5 col-12">
                                <div className="contact-wrapper">
                                    {contactList.map((val, i) => (
                                        <div className="contact-item" key={i}>
                                            <div className="contact-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                <p>{val.desc}</p>
                                                <p>{val.desc2}</p>
                                                <p>{val.desc3}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-12">
                                <GoogleMap />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CourseTwo/>
            <div className="contact-section padding-tb">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{conSubTitle}</span>
                        <h2 className="title">{conTitle}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="contact-form">
                            <div className="form-group">
                                <input value={contactState.name} onChange={(cd)=>setcontactState({...contactState,name:(cd.target.value)})}
                                    type="text"
                                    name="name"
                                    placeholder="Your Name *"
                                />
                            </div>
                            <div className="form-group">
                                <input value={contactState.email} onChange={(cd)=>setcontactState({...contactState,email:(cd.target.value)})}
                                    type="text"
                                    name="email"
                                    placeholder="Your Email *"
                                />
                            </div>
                            <div className="form-group">
                                <input value={contactState.phone} onChange={(cd)=>setcontactState({...contactState,phone:(cd.target.value)})}
                                    type="text"
                                    name="number"
                                    placeholder="Mobile Number *"
                                />
                            </div>
                            <div className="form-group">
                                <input value={contactState.address} onChange={(cd)=>setcontactState({...contactState,address:(cd.target.value)})}
                                    type="text"
                                    name="subject"
                                    placeholder="Your Address *"
                                />
                            </div>
                            <div className="form-group w-100">
                                <textarea  value={contactState.comment} onChange={(cd)=>setcontactState({...contactState,comment:(cd.target.value)})}
                                    rows="8" 
                                    type="text"
                                    placeholder="Your Message"
                                ></textarea>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button className="lab-btn" onClick={()=>SendContactData()}><span>{btnText}</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}



export default ContactPage;
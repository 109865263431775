import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import QuickLink from "../component/sidebar/corporate-link";
import Student from "../component/section/student";
import Testbanner from "../component/section/banner-z";
import FloatImg from "../component/section/float-icon";

import FooterTwo from "../component/layout/footer-3";
import BannerFive from "../component/section/vision-banner";


const subTitle = "About Our METCO";
const title = "Good Quality Products And Excellent Services";

const year = "24+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row ">
                    <div className="col-md-4">
                    <QuickLink/>
                        </div>
                        <div className="col-md-8">
                        <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4 className="animate__heartBeat animate__animated"style={{color:"red"}}>-VISION & MISSION</h4>
                                            <h3 className="animate__lightSpeedInRight  animate__animated">VISION</h3>
                                            <p className="animate__zoomIn animate__animated">To ensure that the customer is delighted in all aspects and building a strong satisfied customer base.<br></br><br></br>

To become a world class-turnkey providers in the Steel Bulidings Industry.
</p>
                                            <h3 className="animate__lightSpeedInRight  animate__animated">MISSION</h3>
                                            <p className="animate__zoomIn animate__animated">To make continued efforts to maintain the quality of our products and services that we offer to our customers. In that effort we constantly strive to maintain and upgrade our system and facilities.</p>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 



const BannerAlpha = () => {

    return (
     
      <section className="banner-section " style={{paddingTop:"0px"}}>
        <div className="row align-items-center flex-row-reverse">
                 <br></br> <br></br>
        <div className="outter hero-video">
<div className="video-container"> <video style={{width:"100%"}}className="bannervideo" autoPlay muted loop>
<source src="assets/video/intro.mp4" type="video/mp4"/>
</video>

  {/* <div className="callout">
    <h1 className="animate__animated animate__bounceInRight">POWERFUL AND<br></br>EFFECTIVE SOLUTIONS</h1>
    <h3 className="desc animate__animated animate__backInUp">GET TO KNOW THE ELEMENTS OF SUCCESSFUL BUSINESS PRACTICE</h3> 
  </div> */}
  </div>
</div>
    </div></section>
    );
}
 
export default BannerAlpha;

import { Link } from "react-router-dom";

const subTitle = "Corporate";
const title = <h2 className="title">Our Team</h2>;



const BannerFive = () => {
    return (
        <section className="banner-section style-5" style={{padding:"200px 0px 50px 0px"}}>
            <div className="container">
                <div className="section-wrapper">
                    <div className="row">
                        <div className="offset-md-6 col-xl-6">
                            <div className="banner-content"> 
                                <h4 className="subtitle animate__animated animate__rotateInDownLeft">{subTitle}</h4>
                                <h2 className="animate__animated animate__flip">{title}</h2>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
    );
}
 
export default BannerFive;
import { Fragment } from "react";
import Footer from "../component/layout/footer-3";
import Header from "../component/layout/header-3";
import Banner from "../component/section/Solar-banner";
import Archive from "../component/sidebar/archive";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import Instagram from "../component/sidebar/instagram";
import PopularPost from "../component/sidebar/popular-post";
import PostCategory from "../component/sidebar/post-category";
import Respond from "../component/sidebar/respond";
import Search from "../component/sidebar/search";
import Tags from "../component/sidebar/tags";

import FloatImg from "../component/section/float-icon";

const socialList = [
    {
        link: '#',
        iconName: 'icofont-facebook',
        className: 'facebook',
    },
    {
        link: '#',
        iconName: 'icofont-twitter',
        className: 'twitter',
    },
    {
        link: '#',
        iconName: 'icofont-linkedin',
        className: 'linkedin',
    },
    {
        link: '#',
        iconName: 'icofont-instagram',
        className: 'instagram',
    },
    {
        link: '#',
        iconName: 'icofont-pinterest',
        className: 'pinterest',
    },
]


 

const BlogSingle = () => {
    return (
        <Fragment>
            <Header />
            <div className="blog-section blog-single padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-12">
                            <article>
                                <div className="section-wrapper">
                                    <div className="row row-cols-1 justify-content-center g-4">
                                        <div className="col">
                                            <div className="post-item style-2">
                                                <div className="post-inner">
                                                    <div className="post-content">
                                                        <h2 className="fade-top reveal">Solar Structures</h2>
                                                        <p  className="fade-left reveal">We have a strong in-house design team to undertake any type of roll forming products for various engineering applications. One such recent development is supply of cold roll formed channels and angles, pre-galvanised or hot dip galvanised finish for SOLAR STRUCTURES. We have supplied to various customers to the tune of 125 MW power plants.</p>
                                                        <blockquote className="fade-zoomin reveal">
                                                            <p>With the wider presence in terms of manufacturing facilities and infrastructure, we can manufacture and supply of cold rolled sections like C, Z, U & L with required length and punching pattern from 0.9mm to 3.2mm thickness and C section of 60x30mm and 30x30mm. L-angle with tolerance as per the relevant Indian and International standards.</p>
                                                            <cite>METCO</cite>
                                                        </blockquote>
                                                         {/* <ul style={{listStyle:"square"}}>
                                                        <li>We have upgraded our manufacturing facilities, multi-locations in India.</li>
                                                        <li>We have installed world class equipments, fully automated beamline facility.</li>
                                                        <li>Perfect Welding process, flawless edge corners and precise designed angles are taken care in our manufacturing systems.</li>
                                                        <li>Total quality control and higher levels of customer satisfaction.</li>
                                                        <li>To ensure enduring qualities and above all values for money to our most valued customers.</li>
                                                        <li>Dedicated team of Engineers and top management.</li>
                                                        </ul> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="col-lg-7 col-12">
                            <img  className="fade-right reveal"src="assets/images/page/solar.png" style={{width:"100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
            <FloatImg/>
            <Footer />
        </Fragment>
    );
}

export default BlogSingle;
import { Fragment, useState } from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from "swiper";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import QuickLink from "../component/sidebar/corporate-link";
import Student from "../component/section/student";
import Testbanner from "../component/section/banner-z";
import FloatImg from "../component/section/float-icon";

import FooterTwo from "../component/layout/footer-3";
import BannerFive from "../component/section/award-banner";


const subTitle = "About Our METCO";
const title = "Good Quality Products And Excellent Services";

const year = "24+";
const expareance = "Years Of Experiences";

const subtitle = "Product Description";
const price = "$ 340.00";
const desc = "Energistia an deliver atactica metrcs after avsionary Apropria trnsition enterpris an sources applications emerging 	psd template.";
const reviwtitle = "Add a Review";

let ProductImgList = [
    {
        imgUrl: 'assets/images/shop/01.jpg',
        imgAlt: 'product thumb',
        title:'Certificate 1',
        desc:'Certificate of Appreciation - Construction of Jawaharlal Nehru stadium - L&T - 1993',
    },
    {
        imgUrl: 'assets/images/shop/02.jpg',
        imgAlt: 'product thumb',
        title:'Certificate 2',
        desc:'Certificate of Appreciation - Construction of Jawaharlal Nehru stadium - L&T - 1993',
    },
    {
        imgUrl: 'assets/images/shop/03.jpg',
        imgAlt: 'product thumb',
        title:'Certificate 3',
        desc:'Certificate of Appreciation - Construction of Jawaharlal Nehru stadium - L&T - 1993',
    },
    {
        imgUrl: 'assets/images/shop/04.jpg',
        imgAlt: 'product thumb',
        title:'Certificate 4',
        desc:'Certificate of Appreciation - Construction of Jawaharlal Nehru stadium - L&T - 1993',
    },
]


let ReviewList = [
    {
        imgUrl: 'assets/images/instructor/01.jpg',
        imgAlt: 'Client thumb',
        name: 'Ganelon Boileau',
        date: 'Posted on Jun 10, 2022 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/instructor/02.jpg',
        imgAlt: 'Client thumb',
        name: 'Morgana Cailot',
        date: 'Posted on Jun 10, 2022 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/instructor/03.jpg',
        imgAlt: 'Client thumb',
        name: 'Telford Bois',
        date: 'Posted on Jun 10, 2022 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
    {
        imgUrl: 'assets/images/instructor/04.jpg',
        imgAlt: 'Client thumb',
        name: 'Cher Daviau',
        date: 'Posted on Jun 10, 2022 at 6:57 am',
        desc: 'Enthusiast build innovativ initiatives before lonterm high-impact awesome theme seo psd porta monetize covalent leadership after without resource.',
    },
]



const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row ">
                    <div className="col-md-4">
                    <QuickLink/>
                        </div>
                        <div className="col-md-8">
                        <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4 className="fade-top reveal"style={{color:"red"}}>-OVERVIEW</h4>
                                            <h3 className="fade-top reveal">AWARDS & RECOGNITIONS</h3>
                                            <p className="fade-zoomin reveal">KSV acknowledges the importance and success of our clients and our employees as the major reasons we are growing and receiving recognition. Here are some of the awards and recognition we have won that bear testimony to the quality of service and the growth of our organization.</p>
                                            <br></br><br></br><div className="swiper-container pro-single-top fade-bottom reveal">
                                                    <Swiper
                                                        spaceBetween={30}
                                                        slidesPerView={1}
                                                        loop={'true'}
                                                        autoplay={{
                                                            delay: 5000,
                                                            disableOnInteraction: false,
                                                        }}
                                                        navigation={{
                                                            prevEl: '.pro-single-prev',
                                                            nextEl: '.pro-single-next',
                                                        }}
                                                        modules={[Autoplay, Navigation]}
                                                    >
                                                        {ProductImgList.map((val, i) => (
                                                            <SwiperSlide key={i}>
                                                                <div className="single-thumb row" >
                                                                <div class="col-md-4">
                                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} style={{float:"left",width:"100%"}}/>
                                                                    </div>
                                                                <div class="col-md-8">
                                                                <h2>{`${val.title}`}</h2>
                                                                <p>{`${val.desc}`}</p></div>
                                                                </div>
                                                            </SwiperSlide>
                                                        ))}
                                                    </Swiper>
                                                </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row justify-content-center">
                    </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 

import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";


const subTitle = "METCO";
const title = "Our Branches";



const branchList = [
    {
        title: 'PONDICHERRY (PDY)',
        since: '2002',
        imgUrl: "url(assets/images/location/Pondy.jpg)",
        imgAlt: 'category rajibraj91 rajibraj',
        address: 'No: 123/3, Thiruvandar Koil Village, Pondicherry - 605 102.',
        phone: '0413 - 2640016.',
        mail: 'sample@metco.com',
        class:'category-item text-center reveal bigEntrance',
        sitelink:"https://maps.app.goo.gl/V89GSCbwTz4i6hYLA",
        mt:"4000",
    },
    {
        title: 'JHAGADIA (GJ)',
        since: '2011',
        imgUrl: "url(assets/images/location/Gujarat.jpg)",
        imgAlt: 'category rajibraj91 rajibraj',
        address: 'Plot No: 824/20 & 21, GIDC Industrial Estate,Jhagadia Dist, Gujarat - 393100.',
        phone: ' 0413 - 2640016.',
        mail: 'sample@metco.com',
        class:'category-item text-center reveal bigEntrance',
        sitelink:"https://maps.app.goo.gl/ZGicjjz8Y8nfX7yw7",
        mt:"1500",
    },
    {
        
        title: 'RANIPET (TN)',
        since: '2012',
        imgUrl: "url(assets/images/location/Ranipet.jpg)",
        imgAlt: 'category rajibraj91 rajibraj',
        address: 'Plot No: M2, Phase III,SIPCOT Industrial Estate, anipet-632405.',
        phone: '0413 - 2640016.',
        mail: 'sample@metco.com',
        class:'category-item text-center reveal bigEntrance',
        sitelink:"https://maps.app.goo.gl/eB1NKFA4qvx1FZuk9",
        mt:"1500",
    },
    {
        title: 'COIMBATORE (TN)',
        since: '2013',
        imgUrl: "url(assets/images/location/Coimbatore.jpg)",
        imgAlt: 'category rajibraj91 rajibraj',
        address: 'SF – 137/1C & SF – 137/2A, EST Salai, Ooratukuppai, Chettipalayam, Coimbatore–641201.',
        phone: ' 0413 - 2640016.',
        mail: 'sample@metco.com',
        class:'category-item text-center reveal bigEntrance',
        sitelink:"https://maps.app.goo.gl/4Bfaqi1HNr6AWWbdA",
        mt:"3000",
    },
    {
        title: 'BANGALORE (KA)',
        since: '2015',
        imgUrl: "url(assets/images/location/Banglore.jpg)",
        imgAlt: 'category rajibraj91 rajibraj',
        address: 'Plot no: 139, K.I.A.D.B, Sompura industrial area, First stage-17-B road,Viduvanda village, Nelamangala-Taluk,Bangalore - 560 103.',
        phone: '0413 - 2640016.',
        mail: 'sample@metco.com',
        class:'category-item text-center reveal bigEntrance',
        sitelink:"https://maps.app.goo.gl/RK2fzkLffpQ22BT4A",
        mt:"2000",
    },
    {
        title: 'SRIPERUMBUDUR (TN)',
        since: '2018',
        imgUrl: "url(assets/images/location/Sriperumbudur.jpg)",
        imgAlt: 'category rajibraj91 rajibraj',
        address: 'Plot No.K-3,Phase-II, Sipcot Industrial Park Sriperumbudur, Mambakkam, Tamil Nadu 602106.',
        phone: '0413 - 2640016.',
        mail: 'sample@metco.com',
        class:'category-item text-center reveal bigEntrance',
        sitelink:"https://maps.app.goo.gl/r5yE9d5UXwZVoJdP6",
        mt:"7000 ",
    },
  
]


const CategoryTwo = () => {
    return (
        <div className="category-section padding-tb section-bg style-2" >
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-4 justify-content-center row-cols-xl-2 row-cols-lg-6 row-cols-sm-2 row-cols-1">
                        {branchList.map((val, i) => (
                            <div className="col" key={i} style={{padding:"30px"}}>
                                <div className={`${val.class}`}>
                                    <div className="row" style={{background:"white",borderRadius:"15px", boxShadow:" 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)"}}>
                                        
                                        <div className="col-md-6" style={{background:`${val.imgUrl}`,borderRadius:"15px 0px 0px 15px",padding:"0px",backgroundSize:"100% auto"}}>
                                        <div style={{backgroundColor:"transparent",marginTop:"300px"}}></div>
                                      
                                        </div>
                                        <div className="col-md-6" style={{textAlign:"left"}}>
                                            <br></br>
                                        <h4 style={{color:"#006fb4"}}>{val.title}</h4>
                                        <br></br>
                                          <div className="row" style={{height:"120px"}}>
                                            <div className="col-1"><i class="icofont-google-map"></i></div>
                                            <div className="col-11"><h6>{val.address}</h6></div>
                                          </div>
                                          {/* <div className="row">
                                            <div className="col-1"><i class="icofont-phone"></i></div>
                                            <div className="col-11"><h6>{val.phone}</h6></div>
                                          </div>
                                          <div className="row">
                                            <div className="col-1"><i class="icofont-envelope"></i></div>
                                            <div className="col-11"><h6>{val.mail}</h6></div>
                                          </div> */}
                                          <div className="row">
                                            <div className="col-1"><i class="icofont-paper-plane"></i></div>
                                            <div className="col-11"><a href={val.sitelink} target="_black"><h6>Get Direction</h6></a></div>
                                          </div>
                                          <div className="row">
                                            
                                          <center><div style={{backgroundColor:"white"}}>In Operation since {val.since}</div></center>
                                        <div style={{backgroundColor:"#006fb4",borderRadius:"0px 0px 15px 0px",fontSize:"20px",color:"white",padding:"10px 0px",marginBottom:"0px"}}><center>{val.mt} MT Annual Capacity</center> </div>
                                       
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CategoryTwo;
import { Component, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer-3";
import Header from "../component/layout/header-3";
import PageHeader from "../component/section/Projects";
import Pagination from "../component/sidebar/pagination";
import PopularPost from "../component/sidebar/popular-post";
import Rating from "../component/sidebar/rating";
import Search from "../component/sidebar/search";
import ShopCategory from "../component/sidebar/shop-category";
import Tags from "../component/sidebar/tags";
import React from "react";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";

import FloatImg from "../component/section/float-icon";
const showResult = "Showing 01 - 12 of 139 Results";


let Projects = [
    {
        imgUrl: 'assets/images/projects/01.jpg',
        imgAlt: 'CLEAR SPAN',
        title: 'CLEAR SPAN',
        popId:'pop-1',
        popAlt:'#pop-1',
        prev:'#pop-24',
        next:'#pop-2',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/02.jpg',
        imgAlt: 'CRANE BUILDING',
        title: 'CRANE BUILDING',
        popId:'pop-2',
        popAlt:'#pop-2',
        prev:'#pop-1',
        next:'#pop-3',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/03.jpg',
        imgAlt: 'CURVED MONOSLOPE',
        title: 'CURVED MONOSLOPE',
        popId:'pop-3',
        popAlt:'#pop-3',
        prev:'#pop-2',
        next:'#pop-4',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/04.jpg',
        imgAlt: 'CURVED RAFTER',
        title: 'CURVED RAFTER',
        popId:'pop-4',
        popAlt:'#pop-4',
        prev:'#pop-3',
        next:'#pop-5',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/05.jpg',
        imgAlt: 'CURVED RAFTER 1',
        title: 'CURVED RAFTER 1',
        popId:'pop-5',
        popAlt:'#pop-5',
        prev:'#pop-4',
        next:'#pop-6',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/06.jpg',
        imgAlt: 'G+1 BUILDING',
        title: 'G+1 BUILDING',
        popId:'pop-6',
        popAlt:'#pop-6',
        prev:'#pop-5',
        next:'#pop-7',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/07.jpg',
        imgAlt: 'LEAN TO',
        title: 'LEAN TO',
        popId:'pop-7',
        popAlt:'#pop-7',
        prev:'#pop-6',
        next:'#pop-8',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/08.jpg',
        imgAlt: 'MEZZANINE BUILDING',
        title: 'MEZZANINE BUILDING',
        popId:'pop-8',
        popAlt:'#pop-8',
        prev:'#pop-7',
        next:'#pop-9',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/09.jpg',
        imgAlt: 'MONOSLOPE',
        title: 'MONOSLOPE',
        popId:'pop-9',
        popAlt:'#pop-9',
        prev:'#pop-8',
        next:'#pop-10',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/10.jpg',
        imgAlt: 'MULTI GABLE',
        title: 'MULTI GABLE',
        popId:'pop-10',
        popAlt:'#pop-10',
        prev:'#pop-9',
        next:'#pop-11',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/11.jpg',
        imgAlt: 'MULTISPAN',
        title: 'MULTISPAN',
        popId:'pop-11',
        popAlt:'#pop-11',
        prev:'#pop-10',
        next:'#pop-12',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/12.jpg',
        imgAlt: 'ROOF SYSTEM',
        title: 'ROOF SYSTEM',
        popId:'pop-12',
        popAlt:'#pop-12',
        prev:'#pop-11',
        next:'#pop-13',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/13.jpg',
        imgAlt: 'CLEAR SPAN',
        title: 'CLEAR SPAN',
        popId:'pop-13',
        popAlt:'#pop-13',
        prev:'#pop-12',
        next:'#pop-14',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/14.jpg',
        imgAlt: 'CRANE BUILDING',
        title: 'CRANE BUILDING',
        popId:'pop-14',
        popAlt:'#pop-14',
        prev:'#pop-13',
        next:'#pop-15',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/15.jpg',
        imgAlt: 'CURVED MONOSLOPE',
        title: 'CURVED MONOSLOPE',
        popId:'pop-15',
        popAlt:'#pop-15',
        prev:'#pop-14',
        next:'#pop-16',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/16.jpg',
        imgAlt: 'CURVED RAFTER',
        title: 'CURVED RAFTER',
        popId:'pop-16',
        popAlt:'#pop-16',
        prev:'#pop-15',
        next:'#pop-17',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/17.jpg',
        imgAlt: 'CURVED RAFTER 1',
        title: 'CURVED RAFTER 1',
        popId:'pop-17',
        popAlt:'#pop-17',
        prev:'#pop-16',
        next:'#pop-18',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/18.jpg',
        imgAlt: 'G+1 BUILDING',
        title: 'G+1 BUILDING',
        popId:'pop-18',
        popAlt:'#pop-18',
        prev:'#pop-17',
        next:'#pop-19',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/19.jpg',
        imgAlt: 'LEAN TO',
        title: 'LEAN TO',
        popId:'pop-19',
        popAlt:'#pop-19',
        prev:'#pop-18',
        next:'#pop-20',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/20.jpg',
        imgAlt: 'MEZZANINE BUILDING',
        title: 'MEZZANINE BUILDING',
        popId:'pop-20',
        popAlt:'#pop-20',
        prev:'#pop-19',
        next:'#pop-21',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/21.jpg',
        imgAlt: 'MONOSLOPE',
        title: 'MONOSLOPE',
        popId:'pop-21',
        popAlt:'#pop-21',
        prev:'#pop-20',
        next:'#pop-22',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/22.jpg',
        imgAlt: 'MULTI GABLE',
        title: 'MULTI GABLE',
        popId:'pop-22',
        popAlt:'#pop-22',
        prev:'#pop-21',
        next:'#pop-23',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/23.jpg',
        imgAlt: 'MULTISPAN',
        title: 'MULTISPAN',
        popId:'pop-23',
        popAlt:'#pop-23',
        prev:'#pop-22',
        next:'#pop-24',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
    {
        imgUrl: 'assets/images/projects/24.jpg',
        imgAlt: 'ROOF SYSTEM',
        title: 'ROOF SYSTEM',
        popId:'pop-24',
        popAlt:'#pop-24',
        prev:'#pop-23',
        next:'#pop-1',
        price: '$200.00',
        desc: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo',
    },
]


const ShopPage = () => {
    const [GridList, setGridList] = useState(true);
    
    return (
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="shop-page padding-tb">
                <div className="container">
        <h1>Projects</h1>
        <div className="gallery-wrapper">
        {Projects.map((val, i) => (
                                        <div className="col-lg-12 col-md-6 col-12" key={i}>
                                              <div className="image-wrapper">
            <a href={`${val.popAlt}`}>
              <img src={`${val.imgUrl}`} alt="" />
              {/* <div className="image-title">{`${val.imgAlt}`}</div> */}
            </a>
          </div>
                                           
                                        </div>
                                    ))}
         
        </div>
        <div className="gallery-lightboxes" style={{marginTop:'50px'}}>
            <br></br>
            <br></br>
        {Projects.map((val, i) => (
             <div className="image-lightbox" id={`${val.popId}`}>
             <div className="image-lightbox-wrapper">
               <a href="#" className="close"></a>
               <a href={`${val.prev}`} className="arrow-left"></a>
               <a href={`${val.next}`} className="arrow-right"></a>
               <img src={`${val.imgUrl}`}  />
               {/* <div className="image-title">{`${val.imgAlt}`}</div> */}
             </div>
           </div>
                                      
                                    ))}
          
        </div>
      </div>
            </div>
            <Footer />
        </Fragment>
    );
}
 
export default ShopPage;
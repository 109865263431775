import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import QuickLink from "../component/sidebar/corporate-link";
import Student from "../component/section/student";
import Testbanner from "../component/section/banner-z";
import FloatImg from "../component/section/float-icon";

import FooterTwo from "../component/layout/footer-3";
import BannerFive from "../component/section/team-banner";


const subTitle = "About Our METCO";
const title = "Good Quality Products And Excellent Services";

const year = "24+";
const expareance = "Years Of Experiences";

const instructorList = [
    {
        imgUrl: 'assets/images/instructor/01.jpg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Emilee Logan',
        degi: 'Master of Education Degree',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'assets/images/instructor/02.jpg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Donald Logan',
        degi: 'Master of Education Degree',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'assets/images/instructor/03.jpg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Oliver Porter',
        degi: 'Master of Education Degree',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
    {
        imgUrl: 'assets/images/instructor/04.jpg',
        imgAlt: 'instructor rajibraj91 rajibraj',
        name: 'Nahla Jones',
        degi: 'Master of Education Degree',
        courseCount: '08 courses',
        studentAnroll: '30 students',
    },
  
]

const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row ">
                    <div className="col-md-4">
                    <QuickLink/>
                        </div>
                        <div className="col-md-8">
                        <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4 style={{color:"red"}}className="animate__heartBeat animate__animated">-OUR TEAM</h4>
                                            <h3 className="animate__lightSpeedInRight  animate__animated">OUR PEOPLE. OUR STRENGTH.
TEAMWORK. OUR INSPIRATION.</h3>
                                            <p className="animate__zoomIn animate__animated">Ever since its inception in the year 2002, we have completed 1000+ ventures in the metal roofing sector. Also we have many prestigious Pre Engineered Building (PEB) projects accomplished to the complete satisfaction of our clients that spreads across india and other foreign countries.<br></br><br></br>
We keep upgrading our production facilities significantly with state of the art machinery of international standards, imported from Germany, Turkey & USA based bradberry Ltd.<br></br><br></br>

We have a strong team of managers and employees well qualified, competent in both technical and with customer orientation, strongly and ably guided by our Managing Director Sri V.Thirumaran.
</p> </div>
                                        {/* <div className="row g-2 justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-2">
                            {instructorList.map((val, i) => (
                                <div className="col" key={i} style={{padding:"20px"}}>
                                    <div className="instructor-item bigEntrance reveal">
                                        <div className="instructor-inner">
                                            <div className="instructor-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="instructor-content">
                                               <h4>{val.name}</h4>
                                                <p>{val.degi}</p>
                                            </div>
                                        </div>
                                        <div className="instructor-footer">
                                            <ul className="lab-ul d-flex flex-wrap justify-content-between align-items-center">
                                                <li><i className="icofont-book-alt"></i> {val.courseCount}</li>
                                                <li><i className="icofont-users-alt-3"></i> {val.studentAnroll}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> */}
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                        
                    </div>
                </div>

            </div>
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 

import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';


const sponsorList = [
    {
        imgUrl: 'assets/images/sponsor/1.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/2.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/3.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/4.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/5.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/6.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/7.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/8.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/9.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/10.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/11.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/12.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/13.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/14.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/15.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/16.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/17.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/18.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/19.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/20.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/21.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/22.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/23.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
    {
        imgUrl: 'assets/images/sponsor/24.jpg',
        imgAlt: 'sponsor rajibraj91 rajibraj',
    },
]


const Sponsor = () => {
    return (
        <div className="sponsor-section section-bg">
            <div className="container">
                <div className="section-wrapper">
                    <div className="sponsor-slider">
                        <Swiper
                            spaceBetween={20}
                            slidesPerView={2}
                            loop={'true'}
                            autoplay={{
                                delay: 5000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay]}
                            breakpoints={{
                                768: {
                                    width: 768,
                                    slidesPerView: 3,
                                },
                                1200: {
                                    width: 1200,
                                    slidesPerView: 5.5,
                                },
                            }}
                        >
                            {sponsorList.map((val, i) => (
                                <SwiperSlide key={i}>
                                    <div className="sponsor-iten">
                                        <div className="sponsor-thumb">
                                           <center> <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></center>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default Sponsor;
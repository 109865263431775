


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8500793233225!2d80.22146697454765!3d13.045213213261183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266fa640f300d%3A0x36402df84092ad8e!2sMetco%20Roof%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1708513126369!5m2!1sen!2sin" style={{height:"460px"}}></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;
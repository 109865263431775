import { Component, Fragment } from "react";
import FooterTwo from "../component/layout/footer-3";
import HeaderThree from "../component/layout/header-3";
import AchievementThree from "../component/section/achievement-3";
import BannerThree from "../component/section/banner-z";
import BlogTwo from "../component/section/blog-2";
import Clients from "../component/section/clients";
import CourseThree from "../component/section/course-3";
import Event from "../component/section/event";
import Feature from "../component/section/feature";
import NewsLetter from "../component/section/newsletter";
import Workshop from "../component/section/workshop";
import WorkshopTwo from "../component/section/workshop-2";
import FloatImg from "../component/section/float-icon";
import InstructorTwo from "../component/section/instructor-2";
import Skill from "../component/section/category-2";

import ContactInfo from "../component/section/contact-info";
import Choose from "../component/section/choose";
import ClientsThree from "../component/section/clients-3";
import Sponsor from "../component/section/sponsor";
const HomeThree = () => {
    return (
        <Fragment>
            <HeaderThree />
             {/* <section className="img-slider">
  <div className="slider-container">
    
    <div className="slide">
      <img src="assets/images/banner/2.jpg"/>
    </div>
    
    <div className="slide">
      <img src="assets/images/banner/3.jpg"/>
    </div>

    <div className="slide">
      <img src="assets/images/banner/4.jpg"/>
    </div>
    
  </div>
</section><br></br>  */}
            <BannerThree /> 
            <Skill/>
            <InstructorTwo/>
            <Sponsor/>
            {/* <ClientsThree/> */}
            <FooterTwo />
            <FloatImg/>
        </Fragment>
    );
}
 
export default HomeThree;
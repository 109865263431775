
import { Link, NavLink } from "react-router-dom";

const desc = "Ever since its inception in the year 2002, we have completed countless ventures in the metal roofing sector and also have many prestigious Pre Engineered Building (PEB) projects accomplished to the complete satisfaction of our clients, spread across the vast expanse of our country.";
const courseTitle = "Corporate";
const quickTitle = "Quick Links";
const tweetTitle = "Contact Info";

const addressList = [
    {
        iconName: 'icofont-google-map',
        text: '33, Chakrapani Street Extn',
    },
    {
        iconName: '',
        text: 'West Mambalam, Chennai, Tamilnadu.',
    },
    {
        iconName: 'icofont-phone',
        text: '+91 44 42024747',
    },
    {
        iconName: 'icofont-phone',
        text: '+91 44 42024848',
    },
    {
        iconName: 'icofont-phone',
        text: '+91 80 56233334',
    },
    {
        iconName: 'icofont-envelope',
        text: 'contact@metcoroof.in',
    },
]

const socialList = [
    {
        iconName: 'icofont-facebook',
        siteLink: 'https://www.facebook.com/profile.php?id=100087247313655',
        className: 'facebook',
    },
    {
        iconName: 'icofont-linkedin',
        siteLink: 'https://www.linkedin.com/company/27903450/admin/feed/posts/',
        className: 'linkedin',
    },
    {
        iconName: 'icofont-instagram',
        siteLink: 'https://www.instagram.com/metcoroof/',
        className: 'instagram',
    },
  
]

const courseList = [
    {
        text: 'Home',
        link: '/home',
    },
    {
        text: 'Our Team',
        link: '/our-team',
    },
    {
        text: 'Core Values',
        link: '/core-values',
    },
    {
        text: 'Vision & Mission',
        link: '/vision-mission',
    },
    {
        text: 'Quality Policy',
        link: '/quality-policy',
    },
]
const quickList = [
    {
        text: 'PEB Steel Structurals',
        link: '/ProductsAndServices',
    },
    {
        text: 'LGSF',
        link: '/ProductsAndServices',
    },
    {
        text: 'Solar Structures',
        link: '/ProductsAndServices',
    },
    {
        text: 'MET SEAM',
        link: '/ProductsAndServices',
    },
    {
        text: 'Roofing Sheets',
        link: '/ProductsAndServices',
    },
    {
        text: 'Purlin & Grits',
        link: '/ProductsAndServices',
    },
    {
        text: 'Accessories',
        link: '/ProductsAndServices',
    },
    {
        text: 'Services',
        link: '/ProductsAndServices',
    },
]

const tweetList = [
    {
        iconName: 'icofont-twitter',
        desc: <p>Aminur islam <a href="#">@CodexCoder Edukon #HTML_Template</a> Grab your item, 50% Big Sale Offer !!</p>,
    },
    {
        iconName: 'icofont-twitter',
        desc: <p>Somrat islam <a href="#">@CodexCoder Edukon #HTML_Template</a> Grab your item, 50% Big Sale Offer !!</p>,
    },
]

const footerbottomList = [
    {
        text: 'Faculty',
        link: '#',
    },
    {
        text: 'Staff',
        link: '#',
    },
    {
        text: 'Students',
        link: '#',
    },
    {
        text: 'Alumni',
        link: '#',
    },
]


const FooterThree = () => {
    return (
        <footer className="style-2">
            <div className="footer-top dark-view padding-tb">
                <div className="container">
                    <div className="row g-4 row-cols-xl-4 row-cols-sm-2 row-cols-1 justify-content-center">
                        <div className="col">
                            <div className="footer-item our-address">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4><img style={{height:"100px"}} src="assets/images/logo/logo-frame.png"/></h4>
                                        </div>
                                        <div className="content">
                                            <p>{desc}</p>
                                           
                                            <ul className="lab-ul social-icons">
                                                {socialList.map((val, i) => (
                                                    <li key={i}>
                                                        <a href={val.siteLink} target="_blank" className={val.className}><i className={val.iconName}></i></a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{courseTitle}</h4>
                                        </div>
                                        <div className="content">
                                            <ul className="lab-ul">
                                                {courseList.map((val, i) => (
                                                    <li key={i}><NavLink to={val.link}>{val.text}</NavLink></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-item">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{quickTitle}</h4>
                                        </div>
                                        <div className="content">

                                            <ul className="lab-ul">
                                                {quickList.map((val, i) => (
                                                    <li key={i}><NavLink to={val.link}>{val.text}</NavLink></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="footer-item twitter-post">
                                <div className="footer-inner">
                                    <div className="footer-content">
                                        <div className="title">
                                            <h4>{tweetTitle}</h4>
                                        </div>
                                        <div className="content">
                                        <ul className="lab-ul office-address">
                                                {addressList.map((val, i) => (
                                                    <li key={i}><i className={val.iconName}></i><span >{val.text}</span></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="section-wrapper">
                        <p>&copy; Copyright 2024. All Rights Reserved |<Link to="https://metcoroof.com" target="blank">Metco Roof Pvt Ltd</Link>  </p>
                        {/* <div className="footer-bottom-list">
                        <p>Maintained by <a href="https://azusystech.com/" target="_blank">Azusys Technology Solutions</a></p>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
}
 
export default FooterThree;
import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-3";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import QuickLink from "../component/sidebar/corporate-link";
import Student from "../component/section/student";
import Testbanner from "../component/section/banner-z";
import FloatImg from "../component/section/float-icon";

import FooterTwo from "../component/layout/footer-3";
import BannerFive from "../component/section/hsec-banner";


const subTitle = "About Our METCO";
const title = "Good Quality Products And Excellent Services";

const year = "24+";
const expareance = "Years Of Experiences";



const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Skilled Instructors',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Get Certificate',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Online Classes',
        desc: 'Distinctively provide acces mutfuncto users whereas communicate leveraged services',
    },
]


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <FloatImg/>
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row ">
                    <div className="col-md-4">
                    <QuickLink/>
                        </div>
                        <div className="col-md-8">
                        <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4 className="fade-top reveal"style={{color:"red"}}>-HSEC POLICY</h4>
                                            <h3 className="fade-top reveal">HEALTH. OUR COMMITMENT.
SAFETY. OUR FOCUS.
ENVIRONMENT. OUR RESPONSIBILITY.</h3>
                                            <p className="fade-zoomin reveal">The HSEC policy is to create an environment for construction activity with safety integrated as an inseparable ingredient. Prevention of accidents is our top priority. Higher productivity with built-in safety awareness, hazard identification and mitigation is the responsibility of every individual.</p>
                                            <h4 className="fade-top reveal">Our Commitments</h4>
                                            <p className="fade-zoomin reveal">We are committed to continual improvement in our HSEC performance.</p>
                                            <h4 className="fade-top reveal">Health & Safety</h4>
                                            <p className="fade-zoomin reveal">We aspire for zero harm to people. Our fundamental belief is that all injuries can be prevented. This responsibility starts with each one of us.</p>
                                            <h4 className="fade-top reveal">Environment</h4>
                                            <p className="fade-zoomin reveal">We care for the environment. We are committed to efficient use of resources, reducing and preventing pollution, and product stewardship.</p>
                                            <h4 className="fade-top reveal">Community</h4>
                                            <p className="fade-zoomin reveal">We strive to be valued corporate citizens in our community. We respect the values and cultural heritage of local people.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

            </div>
            <FooterTwo />
        </Fragment>
    );
}

export default AboutPage;
 